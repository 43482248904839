import React from 'react';
import styled from '@emotion/styled';
import { Checkbox } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import ErrorMessage from '@/components/error-message';
import useGiftCardStore from './hooks/useGiftCardStore';
import { useShallow } from 'zustand/react/shallow';
import AppSelectV2 from '@/components/AppSelectV2';
import { arrayStrToValueLabel } from '@/utils/utils';

export interface GiftCardDataRequest {
  giftAmountId: string;
  giftCardType: string;
  isUseGiftCard: boolean;
  giftCardAmountPrice: number;
}

interface HandwrittenOptionProps {
  error?: string;
  isHandwritten: boolean;
  onChangeGiftCard: (value: GiftCardDataRequest) => void;
}

const HandwrittenOption: React.FC<HandwrittenOptionProps> = ({ isHandwritten, error, onChangeGiftCard }) => {
  const { giftCardList, giftCardTypeList, fetchGiftCardList } = useGiftCardStore(
    useShallow((state) => ({
      giftCardList: state.giftCardList,
      giftCardTypeList: state.giftCardTypeList,
      fetchGiftCardList: state.fetchGiftCardList,
    })),
  );
  const [giftCardData, setGiftCardData] = useState<GiftCardDataRequest>({
    giftAmountId: '',
    giftCardType: '',
    isUseGiftCard: false,
    giftCardAmountPrice: 0,
  });

  useEffect(() => {
    // Fetch giftCardList when the component mounts
    fetchGiftCardList();
  }, [fetchGiftCardList]);

  useEffect(() => {
    if (!onChangeGiftCard || !giftCardData) return;
    onChangeGiftCard(giftCardData);
  }, [giftCardData]);

  const giftCardAmounts = useMemo(
    () => giftCardList.filter((e) => e.card_type === giftCardData.giftCardType),
    [giftCardList, giftCardData.giftCardType],
  );

  const hasError = isHandwritten && giftCardData.isUseGiftCard && Boolean(error);

  return (
    <>
      <CheckboxStyled>
        <Checkbox
          checked={isHandwritten && giftCardData.isUseGiftCard}
          onChange={(e) =>
            setGiftCardData((prev) => ({
              ...prev,
              isUseGiftCard: e.target.checked,
              giftAmountId: '',
              giftCardType: '',
              giftCardAmountPrice: 0,
            }))
          }
        >
          Include Gift Card
        </Checkbox>
      </CheckboxStyled>
      {giftCardData.isUseGiftCard && (
        <GiftCardOptionContainer>
          <div style={{ position: 'relative', marginBottom: '10px', width: '75%' }} id="gift_card_type_container">
            <AppSelectV2
              className="field type-field"
              label="Select Gift Card Type"
              placeholder="Select Gift Card Type"
              // name="type"
              // type="string"
              options={arrayStrToValueLabel(giftCardTypeList)}
              onChange={(value) => setGiftCardData((prev) => ({ ...prev, giftCardType: value, giftAmountId: '' }))}
              value={giftCardData.giftCardType}
              // isGetContainer
              // idParent="gift_card_type_container"
              hasError={hasError}
              allowClear
            />
          </div>
          <div
            style={{ position: 'relative', marginBottom: '10px', width: '100%' }}
            id="gift_card_amount_container"
            className="gift-card-amount"
          >
            <AppSelectV2
              className="field type-field"
              label="Select Gift Amount"
              placeholder="Select Gift Amount"
              // name="type"
              // type="string"
              options={giftCardAmounts}
              fieldNames={{ value: 'simplynoted_gift_id', label: 'gift_value_label' }}
              onChange={(value) => {
                const price = giftCardAmounts?.find((v) => v.simplynoted_gift_id === value)?.price || 0;
                setGiftCardData((prev) => ({ ...prev, giftAmountId: value, giftCardAmountPrice: price }));
              }}
              value={giftCardData?.giftAmountId}
              // isGetContainer
              // idParent="gift_card_amount_container"
              hasError={hasError}
              allowClear
            />
          </div>
        </GiftCardOptionContainer>
      )}
      {hasError ? <ErrorMessage className="errorMsg">{error}</ErrorMessage> : null}
    </>
  );
};

export const CheckboxStyled = styled.div`
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;
  padding-top: 10px;
  .ant-checkbox-wrapper {
    margin-right: 13px;
    color: #fff;
    font-weight: 700;
    .ant-checkbox + span {
      transform: translateY(-5px);
    }

    .ant-checkbox-inner {
      background-color: transparent;
      border-color: #fff !important;
      width: 25px;
      height: 25px;
      &:after {
        font-size: 17px;
        font-weight: 400;
        border: none !important;
        transform: rotate(0) scale(1.4) translate(-50%, -50%) !important;
        left: 13px !important;
        top: 51% !important;
        color: #04caae;
      }
    }
    .ant-checkbox-checked {
      &:hover {
        border-color: #fff !important;
      }
      &:after {
        border-color: #fff !important;
      }
    }
    .ant-checkbox-checked .ant-checkbox-inner:after {
      border: none !important;
      content: 'x';
      font-size: 24px !important;
      left: 14px !important;
      top: 59% !important;
    }
  }
`;

const GiftCardOptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;

export default HandwrittenOption;
