import {
  Campaign,
  CampaignOrder,
  CampaignOrderAmount,
  CampaignOrderFromMailingListParams,
  CampaignOrderFromNeighborhoodParams,
  CampaignOverview,
  CampaignType,
  Drip,
  DripCampaign,
  DripCampaignParams,
  DripParams,
  ICustomTemplate,
  IEmailTemplate,
  PhoneNumberCallScheduling,
  PhoneNumberCollection,
  PreviewCampaignOrder,
  RetargetingCampaign,
  SmartTrigger,
  SmartTriggerDrip,
  SmartTriggerDripParams,
  SmartTriggerParams,
  TotalCount,
  Milestone,
  SmartTriggerActionParams,
  SmartTriggerAction,
  LandingPageQRCode,
  LandingPageQRCodeParams,
  SortOrderEnum,
  DigitalCampaignAudience,
  DigitalCampaignDuration,
  DigitalCampaignType,
  DigitalCampaignTemplate,
  DigitalCampaignOrderParams,
  DigitalCampaignOrder,
  SortDigitalCampaignOrderEnum,
  SortDigitalCampaignTemplateEnum,
  DigitalAdTypes,
  BrokerListingFoundParams,
  sendCampaignForBrokerParams,
  ICustomTemplatePayload,
  OrderStatusEnum,
  DefaultFallbacks,
  sendCampaignForMyListingParams,
} from '@goldfishcode/first-team-real-estate-sdk/libs/api/postalytics/models';
import {
  CloneTemplateBody,
  OrderByEnum,
  PostGridTemplate,
  PostgridTemplateType,
  SessionEditor,
  SessionsVarsBody,
  UserPostGridTemplateFallback,
} from '@goldfishcode/first-team-real-estate-sdk/libs/api/postgrid/models';
import { Pagination } from '@goldfishcode/first-team-real-estate-sdk/libs/type';
import { apiIns } from '@/config/apiClient';
import { formatEmailDataBeforeSendingApi } from '@/utils/api';

class CampaignService {
  static listCampaign(
    agent_id: string,
    page?: number,
    limit?: number,
    postalytics_name?: string,
    campaign_type?: string,
  ): Promise<Pagination<Campaign>> {
    return apiIns.postalytics.listCampaign(agent_id, page, limit, postalytics_name, campaign_type);
  }

  static totalCountFromNeighborhood(
    neighborhood_id: string,
    audience_id: string,
    employer: string,
    interest: string,
    industry: string,
    age_range: string,
  ): Promise<TotalCount> {
    return apiIns.postalytics.totalCountFromNeighborhood(
      neighborhood_id,
      audience_id,
      employer,
      interest,
      industry,
      age_range,
    );
  }
  static totalCountFromMailingList(
    mailing_list_id: string,
    audience_id: string,
    employer: string,
    interest: string,
    industry: string,
    age_range: string,
    is_past_client: boolean,
  ): Promise<TotalCount> {
    return apiIns.postalytics.totalCountFromMailingList(
      mailing_list_id,
      audience_id,
      employer,
      interest,
      industry,
      age_range,
      is_past_client,
    );
  }
  // static campaignOrderAmount(audience_id: string, neighborhood_id: string): Promise<CampaignOrderAmount> {
  //   return apiIns.postalytics.campaignOrderAmount(audience_id, neighborhood_id);
  // }
  static listPastCampaignOrder(
    agent_id: string,
    page?: number,
    limit?: number,
    ordering?: string,
    search?: string,
    status?: OrderStatusEnum,
  ): Promise<Pagination<CampaignOrder>> {
    return apiIns.postalytics.listPastCampaignOrder(
      agent_id,
      page,
      limit,
      ordering ? (ordering as SortOrderEnum) : undefined,
      status,
      search,
    );
  }
  static createCampaignOrderFromNeighborhood(
    campaign_params: CampaignOrderFromNeighborhoodParams,
  ): Promise<CampaignOrder> {
    return apiIns.postalytics.createCampaignOrderFromNeighborhood(campaign_params);
  }
  static createCampaignOrderFromMailingList(
    campaign_params: CampaignOrderFromMailingListParams,
  ): Promise<CampaignOrder> {
    return apiIns.postalytics.createCampaignOrderFromMailingList(campaign_params);
  }

  // static previewCampaignOrderFromNeighborhood(
  //   agent_id: string,
  //   neighborhood_id: string,
  //   audience_id: string,
  // ): Promise<PreviewCampaignOrder> {
  //   return apiIns.postalytics.previewCampaignOrderFromNeighborhood(agent_id, neighborhood_id, audience_id);
  // }
  // static previewCampaignOrderFromMailingList(campaign_id: string): Promise<PreviewCampaignOrder> {
  //   return apiIns.postalytics.previewCampaignOrderFromMailingList(campaign_id);
  // }

  static previewCampaignOrder(campaign_id: string): Promise<PreviewCampaignOrder> {
    return apiIns.postalytics.previewCampaignOrder(campaign_id);
  }

  static campaignOverview(agent_id: string): Promise<CampaignOverview> {
    return apiIns.postalytics.campaignOverview(agent_id);
  }
  static listPastCampaignOrderForManager(
    agent_id?: string,
    page?: number,
    limit?: number,
  ): Promise<Pagination<CampaignOrder>> {
    return apiIns.postalytics.listPastCampaignOrderForManager(agent_id, page, limit);
  }
  static campaignType(campaign_id: string): Promise<CampaignType> {
    return apiIns.postalytics.campaignType(campaign_id);
  }
  static filterListCampaign(campaign_type: string, page?: number, limit?: number): Promise<Pagination<Campaign>> {
    return apiIns.postalytics.FilterListCampaign(campaign_type, page, limit);
  }
  static listPhoneNumber(): Promise<Array<PhoneNumberCollection>> {
    return apiIns.postalytics.listPhoneNumber();
  }
  static deletePhoneNumber(phone_number: string): Promise<any> {
    return apiIns.postalytics.deletePhoneNumber(phone_number);
  }
  static campaignOrderAmountFromMailingList(
    home_count: number,
    mailing_list_ids: Array<string>,
    audience_ids: string,
    employer: string,
    interest: string,
    industry: string,
    age_range: string,
  ): Promise<CampaignOrderAmount> {
    return apiIns.postalytics.campaignOrderAmountFromMailingList(
      home_count,
      '',
      mailing_list_ids,
      audience_ids,
      employer,
      interest,
      industry,
      age_range,
    );
  }
  static campaignOrderAmountFromNeighborhood(
    home_count: number,
    audience_id: string,
    neighborhood_id: string,
    employer: string,
    interest: string,
    industry: string,
    age_range: string,
  ): Promise<CampaignOrderAmount> {
    return apiIns.postalytics.campaignOrderAmountFromNeighborhood(
      home_count,
      audience_id,
      neighborhood_id,
      employer,
      interest,
      industry,
      age_range,
    );
  }
  static getCampaignRecipients(
    campaign_order_id: string,
    page?: number,
    limit?: number,
  ): Promise<Pagination<RetargetingCampaign>> {
    return apiIns.postalytics.getCampaignRecipients(campaign_order_id, page, limit);
  }
  static getCampaignAllRecipients(
    campaign_order_id: string,
    page?: number,
    limit?: number,
  ): Promise<Pagination<RetargetingCampaign>> {
    return apiIns.postalytics.getCampaignAllRecipients(campaign_order_id, page, limit);
  }

  static createDripCampaign(params: DripCampaignParams): Promise<DripCampaign> {
    return apiIns.postalytics.createDripCampaign(params);
  }

  static getDripCampaign(
    agent_id: string,
    page?: number,
    limit?: number,
    name?: string,
    cachebuster?: number,
  ): Promise<Pagination<DripCampaign>> {
    return apiIns.postalytics.getDripCampaign(agent_id, page, limit, name, cachebuster);
  }
  static updateDripCampaign(
    agent_id: string,
    drip_campaign_id: string,
    data: DripCampaignParams,
  ): Promise<DripCampaign> {
    return apiIns.postalytics.updateDripCampaign(agent_id, drip_campaign_id, data);
  }
  static deleteDripCampaign(agent_id: string, drip_campaign_id: string): Promise<any> {
    return apiIns.postalytics.deleteDripCampaign(agent_id, drip_campaign_id);
  }
  static createDrip(agent_id: string, drip_campaign_id: string, data: DripParams): Promise<Drip> {
    return apiIns.postalytics.createDrip(agent_id, drip_campaign_id, data);
  }
  static updateDrip(agent_id: string, drip_campaign_id: string, drip_id: string, data: DripParams): Promise<Drip> {
    return apiIns.postalytics.updateDrip(agent_id, drip_campaign_id, drip_id, data);
  }
  static deleteDrip(agent_id: string, drip_campaign_id: string, drip_id: string): Promise<any> {
    return apiIns.postalytics.deleteDrip(agent_id, drip_campaign_id, drip_id);
  }

  static getSmartTrigger(
    agent_id: string,
    page?: number,
    limit?: number,
    name?: string,
    cachebuster?: number,
  ): Promise<Pagination<SmartTrigger>> {
    return apiIns.postalytics.getSmartTrigger(agent_id, page, limit, name, cachebuster);
  }
  static createSmartTrigger(params: SmartTriggerParams): Promise<SmartTrigger> {
    return apiIns.postalytics.createSmartTrigger(params);
  }
  static updateSmartTrigger(
    agent_id: string,
    smart_trigger_id: string,
    data: SmartTriggerParams,
  ): Promise<SmartTrigger> {
    return apiIns.postalytics.updateSmartTrigger(agent_id, smart_trigger_id, data);
  }
  static deleteSmartTrigger(agent_id: string, smart_trigger_id: string): Promise<any> {
    return apiIns.postalytics.deleteSmartTrigger(agent_id, smart_trigger_id);
  }
  static createSmartTriggerDrip(
    agent_id: string,
    smart_trigger_id: string,
    data: SmartTriggerDripParams,
  ): Promise<SmartTriggerDrip> {
    return apiIns.postalytics.createSmartTriggerDrip(agent_id, smart_trigger_id, data);
  }
  static updateSmartTriggerDrip(
    agent_id: string,
    smart_trigger_id: string,
    drip_id: string,
    data: SmartTriggerDripParams,
  ): Promise<SmartTriggerDrip> {
    return apiIns.postalytics.updateSmartTriggerDrip(agent_id, smart_trigger_id, drip_id, data);
  }
  static deleteSmartTriggerDrip(agent_id: string, smart_trigger_id: string, drip_id: string): Promise<any> {
    return apiIns.postalytics.deleteSmartTriggerDrip(agent_id, smart_trigger_id, drip_id);
  }

  static listEmailTemplate(page?: number, limit?: number, search?: null): Promise<Pagination<IEmailTemplate>> {
    return apiIns.postalytics.listEmailTemplate(page, limit, search);
  }
  static getPhoneNumberForCallScheduling(schedule_id: string): Promise<PhoneNumberCallScheduling> {
    return apiIns.postalytics.getPhoneNumberForCallScheduling(schedule_id);
  }
  static totalEmailCountFromMailingList(
    mailing_list_id: string,
    audience_id: string,
    employer: string,
    interest: string,
    industry: string,
    age_range: string,
    is_past_client: boolean,
  ): Promise<TotalCount> {
    return apiIns.postalytics.totalEmailCountFromMailingList(
      mailing_list_id,
      audience_id,
      employer,
      interest,
      industry,
      age_range,
      is_past_client,
    );
  }
  static totalEmailCountFromNeighborhood(
    neighborhood_id: string,
    audience_id: string,
    employer: string,
    interest: string,
    industry: string,
    age_range: string,
  ): Promise<TotalCount> {
    return apiIns.postalytics.totalEmailCountFromNeighborhood(
      neighborhood_id,
      audience_id,
      employer,
      interest,
      industry,
      age_range,
    );
  }
  static campaignEmailOrderAmountFromNeighborhood(
    home_count: number,
    neighborhood_id: string,
  ): Promise<CampaignOrderAmount> {
    return apiIns.postalytics.campaignEmailOrderAmountFromNeighborhood(home_count, neighborhood_id);
  }
  static campaignEmailOrderAmountFromMailingList(
    home_count: number,
    mailing_list_id: string[],
  ): Promise<CampaignOrderAmount> {
    return apiIns.postalytics.campaignEmailOrderAmountFromMailingList(home_count, mailing_list_id);
  }
  static createEmailCampaignOrderFromNeighborhood(
    campaign_params: CampaignOrderFromNeighborhoodParams,
  ): Promise<CampaignOrder> {
    return apiIns.postalytics.createEmailCampaignOrderFromNeighborhood(campaign_params);
  }
  static createEmailCampaignOrderFromMailingList(
    campaign_params: CampaignOrderFromMailingListParams,
  ): Promise<CampaignOrder> {
    return apiIns.postalytics.createEmailCampaignOrderFromMailingList(campaign_params);
  }
  static listCustomtemplate(
    page?: number,
    limit?: number,
    search?: string | null,
    agent_id?: null,
    is_sms?: boolean,
  ): Promise<Pagination<ICustomTemplate>> {
    return apiIns.postalytics.listCustomtemplate(page, limit, search, agent_id, is_sms);
  }
  static createCustomtemplate(custom_template_infos: ICustomTemplatePayload): Promise<ICustomTemplate> {
    formatEmailDataBeforeSendingApi(custom_template_infos);
    return apiIns.postalytics.createCustomtemplate(custom_template_infos);
  }
  static updateCustomtemplate(id: string, custom_template_infos: ICustomTemplatePayload): Promise<ICustomTemplate> {
    formatEmailDataBeforeSendingApi(custom_template_infos);
    return apiIns.postalytics.updateCustomtemplate(id, custom_template_infos);
  }
  static deleteCustomtemplate(id: string, agent_id?: string): Promise<any> {
    return apiIns.postalytics.deleteCustomtemplate(id, agent_id);
  }
  static getMilestones(): Promise<Milestone[]> {
    return apiIns.postalytics.getMilestones();
  }
  static createSmartTriggerAction(
    agent_id: string,
    SmartTriggerParams: SmartTriggerActionParams,
  ): Promise<SmartTriggerAction> {
    return apiIns.postalytics.createSmartTriggerAction(agent_id, SmartTriggerParams);
  }
  static listCampaignType(): Promise<CampaignType[]> {
    return apiIns.postalytics.listCampaignType();
  }
  static listTemplateDrips(
    template_email_id: string,
    agent_id: string,
  ): Promise<{
    drip_campaigns: DripCampaign[];
    smart_triggers: SmartTrigger[];
  }> {
    return apiIns.postalytics.listTemplateDrips(template_email_id, agent_id);
  }

  static listLandingPageQRCode(page?: number, limit?: number, search?: string): Promise<Pagination<LandingPageQRCode>> {
    return apiIns.postalytics.listLandingPageQRCode(page, limit, search);
  }
  static createLandingPageQRCode(params: LandingPageQRCodeParams): Promise<LandingPageQRCode> {
    return apiIns.postalytics.createLandingPageQRCode(params);
  }
  static updateLandingPageQRCode(
    landing_page_qr_code_id: string,
    data: LandingPageQRCodeParams,
  ): Promise<LandingPageQRCode> {
    return apiIns.postalytics.updateLandingPageQRCode(landing_page_qr_code_id, data);
  }
  static deleteLandingPageQRCode(landing_page_qr_code_id: string): Promise<any> {
    return apiIns.postalytics.deleteLandingPageQRCode(landing_page_qr_code_id);
  }
  static createDigitalCampaignRequestAdmin(
    params: Parameters<typeof apiIns.postalytics.createDigitalCampaignRequestAdmin>[0],
  ) {
    return apiIns.postalytics.createDigitalCampaignRequestAdmin(params);
  }
  static listDigitalCampaignCumulative(
    page: number,
    limit: number,
    ordering?: SortDigitalCampaignOrderEnum,
    search?: string,
  ) {
    return apiIns.postalytics.listDigitalCampaignCumulative(page, limit, ordering, search);
  }
  static listDigitalCampaignAudience(page?: number, limit?: number): Promise<Pagination<DigitalCampaignAudience>> {
    return apiIns.postalytics.listDigitalCampaignAudience(page, limit);
  }
  static listDigitalCampaignDuration(page?: number, limit?: number): Promise<Pagination<DigitalCampaignDuration>> {
    return apiIns.postalytics.listDigitalCampaignDuration(page, limit);
  }
  static listDigitalCampaignType(): Promise<Pagination<DigitalCampaignType>> {
    return apiIns.postalytics.listDigitalCampaignType();
  }
  static listDigitalCampaignTemplate(
    page?: number,
    limit?: number,
    params?: { digital_ad_type?: DigitalAdTypes; ordering?: SortDigitalCampaignTemplateEnum },
  ): Promise<Pagination<DigitalCampaignTemplate>> {
    return apiIns.postalytics.listDigitalCampaignTemplate(page, limit, params?.digital_ad_type, params?.ordering);
  }
  static createDigitalCampaignOrder(params: DigitalCampaignOrderParams): Promise<DigitalCampaignOrder> {
    return apiIns.postalytics.createDigitalCampaignOrder(params);
  }
  static cloneSmartTriggerToDripCampaign(smartTriggerId: string): Promise<any> {
    return apiIns.postalytics.cloneSmartTriggerToDripCampaign(smartTriggerId);
  }
  static cloneDripCampaignToSmartTrigger(dripCampaignId: string): Promise<any> {
    return apiIns.postalytics.cloneDripCampaignToSmartTrigger(dripCampaignId);
  }

  // Broker Listing found pages
  static getBrokerListingFoundDetail(id: string) {
    return apiIns.postalytics.getBrokerListingFoundDetail(id);
  }

  static updateBrokerListingFound(id: string, params: BrokerListingFoundParams) {
    return apiIns.postalytics.updateBrokerListingFound(id, params);
  }

  // My Listing found pages
  static getMyListingFoundDetail(id: string) {
    return apiIns.postalytics.getMyListingFoundDetail(id);
  }

  static sendCampaignForMyListing(id: string, data: sendCampaignForMyListingParams) {
    return apiIns.postalytics.sendCampaignForMyListing(id, data);
  }

  static getPostGridTemplateList(
    page?: number,
    limit?: number,
    me?: boolean,
    use_for_create?: boolean,
    type?: PostgridTemplateType,
    agent_id?: string,
    campaign_type?: string,
    search?: string,
    order_by?: OrderByEnum,
    remove_premium?: boolean,
  ): Promise<Pagination<PostGridTemplate>> {
    return apiIns.postgrid.getPostGridTemplateList(
      page,
      limit,
      me,
      use_for_create,
      type,
      agent_id,
      campaign_type,
      search,
      order_by,
      remove_premium,
    );
  }

  static cloneTemplate(
    templateId: string,
    data: CloneTemplateBody,
  ): Promise<{
    id: string;
  }> {
    return apiIns.postgrid.cloneTemplate(templateId, data);
  }
  static saveUpdateToTemplate(
    templateId: string,
    mls_data: any,
    mailing_list_data: any,
    update_to_template_id: string,
    agent_id?: string,
  ): Promise<{
    id: string;
  }> {
    return apiIns.postgrid.saveUpdateToTemplate(
      templateId,
      mls_data,
      mailing_list_data,
      update_to_template_id,
      agent_id,
    );
  }
  static deleteTemplate(templateId: string, agent_id?: string): Promise<void> {
    return apiIns.postgrid.deleteTemplate(templateId, agent_id);
  }
  static getEditorSession(templateId: string, agent_id?: string): Promise<SessionEditor> {
    return apiIns.postgrid.getEditorSession(templateId, agent_id);
  }
  static sessionsAndVars(): Promise<SessionsVarsBody> {
    return apiIns.postgrid.sessionsAndVars();
  }
  static fetchTemplate(
    templateId: string,
    view?: boolean,
    is_draft?: boolean,
    mls_data?: any,
    mailing_list_data?: any,
    agent_id?: string,
  ): Promise<PostGridTemplate> {
    return apiIns.postgrid.fetchTemplate(templateId, view, is_draft, mls_data, mailing_list_data, agent_id) as any;
  }
  static getUserPostGridTemplateFallback(templateId: string, agent_id?: string): Promise<UserPostGridTemplateFallback> {
    return apiIns.postgrid.getUserPostGridTemplateFallback(templateId, agent_id);
  }
  static buyPremiumTemplate(templateId: string, agent_id?: string): Promise<void> {
    return apiIns.postgrid.buyPremiumTemplate(templateId, agent_id);
  }
  static getWarningVariables(templateId: string): Promise<any> {
    return apiIns.postgrid.getWarningVariables(templateId);
  }
  static sendCampaignForBroker(id: string, data: sendCampaignForBrokerParams) {
    return apiIns.postalytics.sendCampaignForBroker(id, data);
  }
  static setGlobalFallbacks(data: DefaultFallbacks, agent_id?: string): Promise<Record<string, any>> {
    return apiIns.variableTool.setGlobalFallbacks(data, agent_id);
  }
  static getGlobalFallbacks(variables?: string[], agent_id?: string): Promise<any> {
    return apiIns.variableTool.getGlobalFallbacks(variables, agent_id);
  }
}

export default CampaignService;
