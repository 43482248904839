import styled from '@emotion/styled';
import { LandingPageType } from '@goldfishcode/first-team-real-estate-sdk/libs/api/postalytics/models';
import { Empty, Select } from 'antd';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import LandingService from '@/services/landing';
import { InnerLoading } from '../loading/loadingBase';
import AppAction from '@/reducers/app/action';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/reducers/model';
import { LandingPage, UserType } from '@/utils/enum';
import { BaseSelectRef } from 'rc-select/lib/BaseSelect';
import useLandingPageStore from './hooks/useLandingPageStore';
import { omit } from 'lodash';
import AppSelectV2 from '../AppSelectV2';
import AppLabel from '../AppLabel';
import AppButtonV2 from '../AppButtonV2';
import { AppSelectV2Props } from '../AppSelectV2/types';

const { Option } = Select;

interface LandingPageSelectProps
  extends Partial<
    Pick<AppSelectV2Props, 'hasError' | 'errorMessage' | 'size' | 'allowClear' | 'requiredMark' | 'label'>
  > {
  className?: string;
  value?: any;
  placeholder?: string;
  landingPageType: LandingPageType;
  disabled?: boolean;
  handleChange: (value: string) => void;
  handleBlur?: (e: React.FocusEvent) => void;
  handleFocus?: (e: React.FocusEvent) => void;
  onOptionsLoaded?: (options: any[]) => void;
  notFoundValue?: (flag?: boolean) => void;
  isCreateLandingPageVisible?: boolean;
  agentId?: string;
  triggerFetchData?: number;
  name: string;
}

const LandingSelect: React.FC<LandingPageSelectProps> = (props) => {
  const {
    requiredMark,
    label,
    className,
    value,
    placeholder,
    landingPageType,
    disabled,
    handleChange,
    handleBlur,
    handleFocus,
    notFoundValue,
    onOptionsLoaded,
    isCreateLandingPageVisible = true,
    agentId,
    triggerFetchData,
    name,
    ...rest
  } = props;
  const previousLandingPageRef = useRef<string>();
  const inputRef = React.useRef<BaseSelectRef | null>(null);
  const urlPreviewLandingPage = useSelector((state: RootState) => state.app.urlPreviewLandingPage);
  const userType = useSelector((state: RootState) => state.auth.myProfile?.user_type);
  const listNewLandingPage = useSelector((state: RootState) => state.app.newLandingPage);
  const { isLoading, setIsLoading, landingPages, setOptionLandingPage } = useLandingPageStore();

  const optionLandingPage = useMemo(() => landingPages?.[landingPageType] || [], [landingPages, landingPageType]);

  const isManager = userType === UserType.MANAGER;

  const dispatch = useDispatch();

  const onInputRefFocus = () => {
    inputRef.current?.focus();
  };

  useEffect(() => {
    const newLPArr = Object.values(listNewLandingPage)?.flat() || [];
    if (newLPArr?.length)
      setOptionLandingPage(landingPageType, [...(landingPages?.[landingPageType] || []), ...newLPArr]);
    const newLPLength = listNewLandingPage?.[name]?.length;
    if (newLPLength) {
      handleChange(listNewLandingPage?.[name]?.[newLPLength - 1]?.id);
    }
  }, [listNewLandingPage]);

  useEffect(() => {
    const selectedOption = optionLandingPage?.find((v) => v?.id === value);
    if (previousLandingPageRef.current === value && urlPreviewLandingPage?.[selectedOption?.slug]?.slug) return;
    previousLandingPageRef.current = value;
    // Find the selected option from the optionLandingPage
    const isSameLPs = urlPreviewLandingPage?.[selectedOption?.slug]?.slug === selectedOption?.slug;
    if (!value || !selectedOption || isSameLPs) return;
    dispatch({
      type: AppAction.SET_URL_PREVIEW_LANDING_PAGE_MODAL,
      payload: selectedOption
        ? {
            ...urlPreviewLandingPage,
            [selectedOption?.slug]: {
              slug: selectedOption?.slug,
              redirectUrl: selectedOption?.redirect_link,
              is_redirect: selectedOption?.is_redirect,
            },
          }
        : null,
    });
    dispatch({
      type: AppAction.NEW_LANDING_PAGE,
      payload: {
        ...(omit(listNewLandingPage, name) || {}),
      },
    });
  }, [value, optionLandingPage, urlPreviewLandingPage]);
  const getListLandingPageVariable = async (agentId) => {
    if (isManager && !agentId) {
      return { allResults: [] };
    }

    let page = 1;
    const limit = 200;
    const allResults: any[] = [];
    let continueLoop = true;
    while (continueLoop) {
      const data =
        landingPageType === LandingPageType.BROKER_MLS
          ? await LandingService.getListBrokerLandingPageVariable(page, limit)
          : await LandingService.listLandingPageVariable(page, limit, landingPageType || undefined, agentId);
      if (data.results) {
        allResults.push(...data.results);
      }
      if (data.links && data.links.next) {
        page++;
      } else {
        continueLoop = false;
      }
    }
    const isFoundLP = allResults.some((v) => v.id === value);
    if (value && notFoundValue) notFoundValue(!isFoundLP);
    return { allResults };
  };
  const fetchListLanding = useCallback(
    async (agentId) => {
      try {
        if (!landingPageType) return;
        setIsLoading(true);

        const { allResults = [] } = await getListLandingPageVariable(agentId);

        dispatch({
          type: AppAction.NEW_LANDING_PAGE,
          payload: {},
        });
        setOptionLandingPage(landingPageType, allResults);
        onOptionsLoaded && onOptionsLoaded(allResults);
      } catch (error) {
        onOptionsLoaded && onOptionsLoaded([]);
      } finally {
        setIsLoading(false);
      }
    },
    [landingPageType, triggerFetchData],
  );

  useEffect(() => {
    dispatch({
      type: AppAction.SET_URL_PREVIEW_LANDING_PAGE_MODAL,
      payload: null,
    });
    // This trick will help avoid to duplicate calling of listLandingPageVariable API for muiltiple QR codes in a template.
    // This one will use with caching flow in SDK to reach the best performance.
    const indexString = name.split('qr_code_')[1];
    const index = Number(indexString || 0);
    if (!name.startsWith('qr_code') || (name.startsWith('qr_code') && index === 0)) {
      fetchListLanding(agentId);
    } else {
      setTimeout(() => fetchListLanding(agentId), 2000);
    }
  }, [agentId, triggerFetchData]);

  const selectedOption = useMemo(() => optionLandingPage?.find((v) => v?.id === value), [optionLandingPage, value]);

  const handlePreviewLandingPage = () => {
    dispatch({
      type: AppAction.VISIBLE_PREVIEW_LANDING_PAGE_MODAL,
      payload: selectedOption?.slug,
    });
  };

  return (
    <Container className={className}>
      <div className="wp-label">
        {label && <AppLabel label={label} requiredMark={requiredMark} onClick={onInputRefFocus} />}
        {value && urlPreviewLandingPage?.[selectedOption?.slug]?.slug && (
          <AppButtonV2
            width="100px"
            fontSize={12}
            height={22}
            color="Pin"
            roundedFull
            size="small"
            onClick={handlePreviewLandingPage}
            className="preview-landing-page"
            disabled={disabled}
            uppercase={false}
          >
            Preview
          </AppButtonV2>
        )}
      </div>
      <AppSelectV2
        {...rest}
        value={!isLoading && value !== '' ? value : null}
        showSearch
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        ref={inputRef}
        placeholder={placeholder}
        disabled={disabled}
        allowClear
        loading={isLoading}
        notFoundContent={
          isLoading ? (
            <InnerLoading spinning={isLoading} />
          ) : (
            <Empty className="empty-data" image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )
        }
        onClear={() => {
          dispatch({
            type: AppAction.SET_URL_PREVIEW_LANDING_PAGE_MODAL,
            payload: {
              ...urlPreviewLandingPage,
              [selectedOption?.slug]: {
                slug: '',
                redirectUrl: null,
                is_redirect: false,
              },
            },
          });
          dispatch({
            type: AppAction.NEW_LANDING_PAGE,
            payload: {
              ...(omit(listNewLandingPage, name) || {}),
            },
          });
        }}
      >
        {isCreateLandingPageVisible && !isManager && (
          <Option className="option option-create" value={LandingPage.CREATE_LANDING_PAGE}>
            <span style={{ fontWeight: '700' }}>Add Your Own Landing Page</span>
          </Option>
        )}
        {optionLandingPage?.map((item, index) => {
          return (
            <Option className="option" key={index + item?.id} value={item?.id} label={item?.name}>
              {item?.name}
            </Option>
          );
        })}
      </AppSelectV2>
    </Container>
  );
};

export default LandingSelect;

const Container = styled.div`
  width: 100%;
  .wp-label {
    display: flex;
    justify-content: space-between;
  }
  .ant-spin {
    color: #150056;
    > .anticon-loading {
      font-size: 20px !important;
      opacity: 0.6;
    }
  }

  &.mt-10 {
    margin-top: 10px;
  }
  &.drip-field-lp {
    width: 100%;
    .s-label {
      margin-left: 0px;
      margin-top: 0px;
    }
  }
`;
