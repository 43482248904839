import AppInputV2 from '@/components/AppInputV2';
import AppLabel from '@/components/AppLabel';
import AppSelectV2 from '@/components/AppSelectV2';
import TextEditor from '@/components/custom-html-editor/text-editor';
import { CheckboxStyled } from '@/components/custom-modals/modal-mail-action/HandwrittenOption';
import { HandwrittenTemplateCustom } from '@/components/dashboard/campaign/ScrollTemplate/hooks/useScrollTemplate';
import ErrorMessage from '@/components/error-message';
import ImageCrop from '@/components/image-crop';
import LandingSelect from '@/components/select/landing-select';
import UploadDigitalMedia, { SelectedFileNameDigitalMediaType, UploadMediaType } from '@/components/UploadDigitalMedia';
import AppAction from '@/reducers/app/action';
import { SCAN_NOW_QR_CODE, SymplynoteFont } from '@/utils/constant';
import { KEY_LANDING_TYPE, LandingPage } from '@/utils/enum';
import { css } from '@emotion/react';
import { CardTypeEnum, HeaderTypeEnum } from '@goldfishcode/first-team-real-estate-sdk/libs/api/handwriting/models';
import { DigitalAdTypes, LandingPageType } from '@goldfishcode/first-team-real-estate-sdk/libs/api/postalytics/models';
import { MailingExtra } from '@goldfishcode/first-team-real-estate-sdk/libs/api/tools/variable/models';
import { Checkbox, Radio, Tooltip } from 'antd';
import classNames from 'classnames';
import { FormikProps } from 'formik';
import React, { Dispatch, FC, SetStateAction } from 'react';
import { useDispatch } from 'react-redux';
import HeaderText from '../header-text';
import {
  handwrittenCardTypes,
  HandwrittenCreatingType,
  handwrittenFontStyles,
  HANDWRITTEN_MESSAGE_LIMIT,
  HANDWRITTEN_SIGNATURE_LIMIT,
} from '../hooks/useCreateHandwritten';
import { HandwrittenWrapperStyled } from './HandwrittenCreatorStyled';

interface HandwrittenCreatorProps {
  templateData?: HandwrittenTemplateCustom;
  isLoading?: boolean;
  mailing?: MailingExtra;
  className?: string;
  formik: FormikProps<HandwrittenCreatingType>;
  setCardSide: Dispatch<SetStateAction<CardSideEnum>>;
  cardSide: CardSideEnum;
  onUploadHandwrittenImage: (file: SelectedFileNameDigitalMediaType | null, key: string) => void;
  isDisabledFrontImage: boolean;
  isUseSVImgFront: boolean;
  isDisabledBackImage: boolean;
  isDisabledHeader: boolean;
  isUseSVImgBack: boolean;
  frontImageSource?: string;
  backImageSource?: string;
  debouncedValidate: (editor: any) => void;
  inputRef: React.RefObject<any>;
  updateInputRef: (editor: any) => void;
}

const HANDWRITTEN_RESOLUTION = 1380 / 1060;

enum CardSideEnum {
  CARD_FRONT = 'CARD_FRONT',
  CARD_INSIDE = 'CARD_INSIDE',
  CARD_BACK = 'CARD_BACK',
}

const HandwrittenCreatorView: FC<HandwrittenCreatorProps> = (props) => {
  const {
    mailing,
    templateData,
    isLoading,
    className,
    formik,
    setCardSide,
    cardSide,
    isDisabledFrontImage,
    isUseSVImgFront,
    onUploadHandwrittenImage,
    isDisabledBackImage,
    isDisabledHeader,
    isUseSVImgBack,
    frontImageSource,
    backImageSource,
    debouncedValidate,
    inputRef,
    updateInputRef,
  } = props;
  const dispatch = useDispatch();
  return (
    <HandwrittenWrapperStyled className={className}>
      <div className="handwritten-input-container haft-size">
        <AppInputV2
          className="field template-name-field"
          label="Template Name"
          requiredMark
          name="templateName"
          type="string"
          placeholder="Enter Template Name"
          onChange={(e) => {
            formik.setFieldError('templateName', undefined);
            formik.setFieldValue('templateName', e.target.value);
          }}
          value={formik.values.templateName}
          size="large"
          errorMessage={formik.errors.templateName}
        />
      </div>
      <div id="handwritten-card_type_creator" className="handwritten-input-container haft-size">
        <LandingSelect
          requiredMark
          className="field base-field landing-field"
          label="Select Landing Page"
          name={KEY_LANDING_TYPE.landing_page}
          placeholder="Select Landing Page"
          handleChange={(value) => {
            formik.setFieldError('landingPage', undefined);
            if (value === LandingPage.CREATE_LANDING_PAGE) {
              dispatch({
                type: AppAction.VISIBLE_CREATE_LANDING_PAGE_MODAL,
                payload: {
                  visible: true,
                  type: LandingPageType.NORMAL,
                  fieldName: KEY_LANDING_TYPE.landing_page,
                },
              });
            } else {
              formik.setFieldValue('landingPage', value);
            }
          }}
          value={formik.values.landingPage}
          landingPageType={LandingPageType.NORMAL}
          notFoundValue={(isNotFound) => {
            if (isNotFound) formik.setFieldValue('landingPage', '');
          }}
          onOptionsLoaded={() => {
            if (templateData?.landing_page) {
              formik.setFieldValue('landingPage', templateData?.landing_page);
            }
          }}
          errorMessage={formik.errors.landingPage}
          disabled={isLoading}
        />
      </div>

      <div id={`id_container_handwriting_create`} className="handwritten-input-container haft-size">
        <AppSelectV2
          className="field type-field"
          label="Card Type"
          placeholder="Select Card Type"
          options={handwrittenCardTypes}
          onChange={(v) => {
            setCardSide(CardSideEnum.CARD_FRONT);
            if (v === CardTypeEnum.Fold) {
              formik.setErrors({ ...formik.errors, headerText: undefined, headerImage: undefined });
              formik.setValues({
                ...formik.values,
                headerImage: null,
                headerType: HeaderTypeEnum.Image,
                // headerText: {} as any,
                cardType: v,
              });
            } else if (v === CardTypeEnum.Flat) {
              formik.setValues({
                ...formik.values,
                cardType: v,
                backImage: null,
              });
              formik.setFieldError('backImage', undefined);
            }
            formik.setFieldValue('isUseSVImgAsBack', false);
          }}
          value={formik.values.cardType}
          // isGetContainer
          requiredMark
          // idParent="handwritten-card_type_creator"
        />
      </div>
      <div
        className="handwritten-input-container handwritten-input-editor-container"
        css={css`
          display: flex;
          flex-direction: column;
          .handwritten-upload-image {
            display: flex;
            gap: 10px;
          }
          .digital-btn_label_group {
            min-width: 220px;
          }
        `}
      >
        <div className="image-upload-section">
          <div
            className={classNames('handwritten-input-container handwritten-img', {
              hidden: cardSide !== CardSideEnum.CARD_FRONT,
            })}
          >
            <div className="handwritten-upload-image-container">
              <ImageCrop aspect={HANDWRITTEN_RESOLUTION} showGrid modalTitle="Upload Front Image">
                <UploadDigitalMedia
                  className="handwritten-upload-image"
                  title="Upload Front Image"
                  requiredMark
                  value={formik.values.frontImage || {}}
                  buttonColor="pink"
                  alignPreviewImage="left"
                  onSelectedFile={async (file) => {
                    if (formik.values.isUseSVImgAsFront) return;
                    await onUploadHandwrittenImage(file, 'frontImage');
                  }}
                  mode={DigitalAdTypes.DIGITAL_AD_IMAGE}
                  uploadType={UploadMediaType.HANDWRITTEN_IMAGE_MEDIA}
                  disabled={isDisabledFrontImage}
                />
              </ImageCrop>
              <span className="or-divide">OR</span>
              <CheckboxStyled className="checkbox-customize">
                <Checkbox
                  checked={formik.values.isUseSVImgAsFront}
                  onChange={(e) => {
                    const checked = e.target.checked;
                    formik.setFieldValue('isUseSVImgAsFront', checked);
                    if (checked) {
                      formik.setFieldValue(
                        'frontImage',
                        mailing?.street_view_image
                          ? {
                              selectedFileData: {
                                url: mailing?.street_view_image,
                              },
                            }
                          : null,
                      );
                      formik.setFieldError('frontImage', undefined);
                    } else if (
                      isUseSVImgFront ||
                      mailing?.street_view_image === formik.values.frontImage?.selectedFileData?.url
                    ) {
                      formik.setFieldValue('frontImage', null);
                    }
                  }}
                >
                  <span>Use Street View Image</span>
                </Checkbox>
              </CheckboxStyled>
            </div>

            {formik.errors.frontImage ? (
              <ErrorMessage className="errorMsg">{formik.errors.frontImage}</ErrorMessage>
            ) : null}
          </div>
          <div
            className={classNames('handwritten-input-container handwritten-img', {
              hidden: formik.values.cardType === CardTypeEnum.Flat || ![CardSideEnum.CARD_BACK].includes(cardSide),
            })}
          >
            <Tooltip
              title={isDisabledBackImage ? '"5x7" Flat type does not support Back Image' : ''}
              color="#fff"
              overlayStyle={{
                border: 'none',
              }}
              overlayInnerStyle={{
                fontSize: '12px',
                fontWeight: 600,
                color: '#150056',
                borderRadius: '4px',
                border: 'none',
                transform: 'translateY(2px)',
              }}
              align={{ offset: [0, 10] }}
              placement="topLeft"
              getTooltipContainer={() => document.getElementById('id_handwritten_header_image') as HTMLElement}
              destroyTooltipOnHide
              autoAdjustOverflow={false}
            >
              <div className="handwritten-upload-image-container">
                <ImageCrop aspect={HANDWRITTEN_RESOLUTION} showGrid modalTitle="Upload Back Image">
                  <UploadDigitalMedia
                    className={classNames('handwritten-upload-image', {
                      'disabled-field': isDisabledBackImage,
                    })}
                    title="Upload Back Image"
                    requiredMark
                    value={formik.values.backImage || {}}
                    buttonColor="pink"
                    alignPreviewImage="left"
                    onSelectedFile={async (file) => {
                      if (formik.values.isUseSVImgAsBack) return;
                      await onUploadHandwrittenImage(file, 'backImage');
                    }}
                    mode={DigitalAdTypes.DIGITAL_AD_IMAGE}
                    uploadType={UploadMediaType.HANDWRITTEN_IMAGE_MEDIA}
                    disabled={isDisabledBackImage}
                  />
                </ImageCrop>
                <span className="or-divide">OR</span>
                <CheckboxStyled className="checkbox-customize">
                  <Checkbox
                    checked={formik.values.isUseSVImgAsBack}
                    onChange={(e) => {
                      const checked = e.target.checked;
                      formik.setFieldValue('isUseSVImgAsBack', checked);
                      if (checked) {
                        formik.setFieldValue(
                          'backImage',
                          mailing?.street_view_image
                            ? {
                                selectedFileData: {
                                  url: mailing?.street_view_image,
                                },
                              }
                            : null,
                        );
                        formik.setFieldError('backImage', undefined);
                      } else if (
                        isUseSVImgBack ||
                        mailing?.street_view_image === formik.values.backImage?.selectedFileData?.url
                      ) {
                        formik.setFieldValue('backImage', null);
                      }
                    }}
                  >
                    <span>Use Street View Image</span>
                  </Checkbox>
                </CheckboxStyled>
              </div>
              {formik.errors.backImage ? (
                <ErrorMessage className="errorMsg">{formik.errors.backImage}</ErrorMessage>
              ) : null}
            </Tooltip>
          </div>

          <div
            className={classNames('handwritten-input-container handwritten-header-section', {
              hidden: isDisabledHeader || cardSide !== CardSideEnum.CARD_BACK,
            })}
          >
            <div className="handwritten-header-option">
              <Radio.Group
                value={formik.values.headerType}
                size="large"
                disabled={isDisabledHeader}
                className={classNames('', { 'disabled-field': isDisabledHeader })}
              >
                <Radio.Button
                  value={HeaderTypeEnum.Image}
                  onChange={(e) => formik.setFieldValue('headerType', e.target.value)}
                >
                  Header Image
                </Radio.Button>
                <Radio.Button
                  value={HeaderTypeEnum.Text}
                  onChange={(e) => formik.setFieldValue('headerType', e.target.value)}
                >
                  Header Text
                </Radio.Button>
              </Radio.Group>
            </div>

            <div
              className={classNames('handwritten-input-container', {
                hidden: formik.values.headerType === HeaderTypeEnum.Text,
              })}
              id="id_handwritten_header_image"
            >
              <Tooltip
                title={isDisabledHeader ? '"5x7" Folded type does not support Header Image' : ''}
                color="#fff"
                overlayStyle={{
                  border: 'none',
                }}
                overlayInnerStyle={{
                  fontSize: '12px',
                  fontWeight: 600,
                  color: '#150056',
                  borderRadius: '4px',
                  border: 'none',
                  transform: 'translateY(2px)',
                }}
                align={{ offset: [0, 10] }}
                placement="topLeft"
                getTooltipContainer={() => document.getElementById('id_handwritten_header_image') as HTMLElement}
                destroyTooltipOnHide
                autoAdjustOverflow={false}
              >
                <ImageCrop aspect={HANDWRITTEN_RESOLUTION} showGrid modalTitle="Upload Header Image">
                  <UploadDigitalMedia
                    className={classNames('handwritten-upload-image', {
                      'disabled-field': isDisabledHeader,
                    })}
                    title="Header Image"
                    requiredMark
                    value={formik.values.headerImage || {}}
                    buttonColor="pink"
                    alignPreviewImage="left"
                    onSelectedFile={async (file) => {
                      await onUploadHandwrittenImage(file, 'headerImage');
                    }}
                    mode={DigitalAdTypes.DIGITAL_AD_IMAGE}
                    uploadType={UploadMediaType.HANDWRITTEN_IMAGE_MEDIA}
                    disabled={isDisabledHeader}
                    isUseDisclaimer={formik.values.cardType !== CardTypeEnum.Fold}
                  />
                </ImageCrop>
                {formik.errors.headerImage ? (
                  <ErrorMessage className="errorMsg">{formik.errors.headerImage}</ErrorMessage>
                ) : null}
              </Tooltip>
            </div>

            <div
              className={classNames('handwritten-input-container', {
                hidden: formik.values.headerType === HeaderTypeEnum.Image,
              })}
            >
              <HeaderText
                onHeaderTextChanged={(value) => {
                  if (value?.data) formik.setFieldError('headerText', undefined);
                  formik.setFieldValue('headerText', value);
                }}
                headerTextValue={JSON.parse(templateData?.header_text || '{}')}
                headerTextError={formik.errors.headerText}
                disabled={isDisabledHeader}
              />
            </div>
          </div>
        </div>
        <div
          css={css`
            display: flex;
            gap: 10px;
          `}
        >
          <div className={classNames('handwritten-preview-container', {})}>
            <AppLabel>Card Preview</AppLabel>
            <div
              className={classNames('handwritten-input-preview handwritten-card-front', {
                hidden: cardSide !== CardSideEnum.CARD_FRONT,
              })}
            >
              {/* {formik.values.frontImage?.selectedFileData?.url && (
                <img src={formik.values.frontImage?.selectedFileData?.url} alt="front-image" />
              )} */}
              {frontImageSource && (
                <img style={{ display: 'block', margin: 'auto' }} src={frontImageSource} alt="front-image" />
              )}
            </div>
            <div
              className={classNames('handwritten-input-preview handwritten-card-back', {
                hidden: ![CardSideEnum.CARD_BACK, CardSideEnum.CARD_INSIDE].includes(cardSide),
              })}
            >
              <div
                className={classNames('handwritten-input-header', {
                  hidden: formik.values.cardType === CardTypeEnum.Fold,
                })}
              >
                <div
                  className={classNames(
                    `handwritten-header-text-preview simply-noted-API ${formik.values.headerText?.fontType
                      ?.split(' ')
                      ?.join('_')}`,
                    {
                      hidden: formik.values.headerType === HeaderTypeEnum.Image,
                      'placeholder Times_New_Roman': !formik.values.headerText?.data,
                      centerAlign: !formik.values.headerText?.data,
                    },
                  )}
                  style={{
                    textAlign: !formik.values.headerText?.data ? undefined : formik.values.headerText?.textAlign,
                    color: `${!formik.values.headerText?.data ? '' : formik.values.headerText?.fontColor}`,
                    fontSize: `${
                      !formik.values.headerText?.data
                        ? ''
                        : Number(formik.values.headerText?.fontSize || 15) * 1.5 + 'px' //hard fontsize is 30px if not select font size
                    }`,
                  }}
                >
                  {formik.values.headerText?.data || 'Header'}
                </div>
                <div
                  className={classNames('handwritten-header-text-preview simply-noted-API', {
                    hidden: formik.values.headerType === HeaderTypeEnum.Text,
                    'placeholder Times_New_Roman': !formik.values.headerImage?.selectedFileData?.url,
                  })}
                  style={{ textAlign: 'center' }}
                >
                  {formik.values.headerImage?.selectedFileData?.url ? (
                    <img src={formik.values.headerImage?.selectedFileData?.url} alt="header-image" />
                  ) : (
                    'Header'
                  )}
                </div>
              </div>
              <div
                style={{ minHeight: 'calc(100% - 160px)' }}
                className={classNames({
                  hidden: formik.values.cardType === CardTypeEnum.Fold && cardSide === CardSideEnum.CARD_BACK,
                })}
              >
                <div
                  className={classNames(`handwritten-message-preview simply-noted-API ${formik.values.fontStyle}`, {
                    hidden: formik.values.cardType === CardTypeEnum.Fold && cardSide === CardSideEnum.CARD_BACK,
                    placeholder: !formik.values.message,
                  })}
                  dangerouslySetInnerHTML={{
                    __html: formik.values.message || 'Your custom message text will go here...',
                  }}
                  style={{ fontSize: '40px', padding: '0 10px', minHeight: '145px', textAlign: 'justify' }}
                />
                <div
                  className={classNames(`handwritten-message-preview simply-noted-API ${formik.values.fontStyle}`, {
                    hidden: formik.values.cardType === CardTypeEnum.Fold && cardSide === CardSideEnum.CARD_BACK,
                  })}
                  style={{ margin: '30px 10px 10px 50%', minHeight: '55px', wordWrap: 'break-word' }}
                  dangerouslySetInnerHTML={{ __html: formik.values.signature || '' }}
                />
              </div>
              {formik.values?.landingPage && formik.values.cardType === CardTypeEnum.Flat && (
                <div style={{ width: '100%', height: '70px', textAlign: 'right' }}>
                  <img src={SCAN_NOW_QR_CODE} alt="scannow_qrcode" />
                </div>
              )}

              {backImageSource && formik.values.cardType === CardTypeEnum.Fold && cardSide === CardSideEnum.CARD_BACK && (
                <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                  <img style={{ display: 'block', margin: 'auto' }} src={backImageSource} alt="back-image" />
                  {formik.values?.landingPage &&
                    formik.values.cardType === CardTypeEnum.Fold &&
                    cardSide === CardSideEnum.CARD_BACK && (
                      <div
                        style={{
                          width: '100%',
                          height: '70px',
                          textAlign: 'right',
                          position: 'absolute',
                          bottom: '0',
                        }}
                      >
                        <img src={SCAN_NOW_QR_CODE} alt="scannow_qrcode" />
                      </div>
                    )}
                </div>
              )}
            </div>

            <div className="handwritten-card-option">
              <Radio.Group value={cardSide} size="large">
                <Radio.Button value={CardSideEnum.CARD_FRONT} onChange={(e) => setCardSide(e.target.value)}>
                  Card Front
                </Radio.Button>
                {formik.values.cardType === CardTypeEnum.Fold && (
                  <Radio.Button value={CardSideEnum.CARD_INSIDE} onChange={(e) => setCardSide(e.target.value)}>
                    Card Inside
                  </Radio.Button>
                )}
                <Radio.Button value={CardSideEnum.CARD_BACK} onChange={(e) => setCardSide(e.target.value)}>
                  Card Back
                </Radio.Button>
              </Radio.Group>
            </div>
            <div className="handwritten-input-signature">
              <div className="handwritten-editor-header">
                <AppLabel label="Signature" requiredMark />
                <p
                  className={classNames('handwritten-limit-char', {
                    errorMsg:
                      (formik.values.signature?.replaceAll('\n', '')?.length || 0) > HANDWRITTEN_SIGNATURE_LIMIT,
                  })}
                >
                  {HANDWRITTEN_SIGNATURE_LIMIT - (formik.values.signature || '')?.length} characters remaining
                </p>
              </div>
              <AppInputV2
                className="field template-name-field"
                name="signature"
                type="string"
                placeholder="Enter Signature"
                requiredMark
                size="large"
                onChange={(e) => {
                  formik.setFieldError('signature', undefined);
                  formik.setFieldValue('signature', e.target.value);
                }}
                value={formik.values.signature}
              />

              {formik.errors.signature ? (
                <ErrorMessage className="errorMsg">{formik.errors.signature}</ErrorMessage>
              ) : null}
            </div>
          </div>
          <div
            className={classNames('handwritten-input-message', {
              hidden:
                cardSide === CardSideEnum.CARD_FRONT ||
                (cardSide === CardSideEnum.CARD_BACK && formik.values.cardType === CardTypeEnum.Fold),
            })}
            css={css`
              max-width: 49%;
              width: 100%;
            `}
          >
            <div className="handwritten-editor-header">
              <AppLabel label="Message" requiredMark></AppLabel>
              <p
                className={classNames('handwritten-limit-char', {
                  errorMsg: (formik.values.message?.replaceAll('\n', '')?.length || 0) > HANDWRITTEN_MESSAGE_LIMIT,
                })}
              >
                {HANDWRITTEN_MESSAGE_LIMIT - (formik.values.message?.replaceAll('\n', '')?.length || 0)} characters
                remaining
              </p>
            </div>

            <TextEditor
              onChange={(editor) => {
                debouncedValidate(editor);
              }}
              placeholder={'Enter Message...'}
              editorHeight="290px"
              useSpecialCharEntity={false}
              handleEditorReady={(editor) => {
                updateInputRef(editor);
                if (inputRef.current) {
                  inputRef.current?.setData(templateData?.message || '', {
                    callback: () => {
                      editor.document.$.body.style.fontSize = '20px';
                    },
                  });
                }
              }}
            />

            {formik.errors.message ? <ErrorMessage className="errorMsg">{formik.errors.message}</ErrorMessage> : null}

            <div id="handwritten-font_style" className="handwritten-message-font">
              <AppSelectV2
                label="Message Font"
                placeholder="Select Font"
                options={handwrittenFontStyles}
                onChange={(v) => {
                  inputRef.current.document.$.body.style.fontFamily = SymplynoteFont[v];
                  formik.setFieldValue('fontStyle', v);
                }}
                value={formik.values.fontStyle}
                requiredMark
                hasError={Boolean(formik.errors.fontStyle)}
              />
            </div>
          </div>
        </div>
      </div>
    </HandwrittenWrapperStyled>
  );
};
export default HandwrittenCreatorView;
