import styled from '@emotion/styled';
import NextSwiperIcon from '@/assets/icons/next-swiper.svg';
import PreviousSwiperIcon from '@/assets/icons/previous-swiper.svg';

const ScrollTemplateStyled = {
  Wrapper: styled.div<{ isDigitalCampaign: boolean; disabled: boolean }>`
    width: 100%;
    height: 100%;

    .swiper-button-next {
      background-image: ${`url(${NextSwiperIcon})`};
      background-repeat: no-repeat;
      background-size: 100% auto;
      background-position: center;
      width: 30px;
      height: 30px;
    }

    .swiper-button-next::after {
      display: none;
    }

    .swiper-button-prev {
      background-image: ${`url(${PreviousSwiperIcon})`};
      background-repeat: no-repeat;
      background-size: 100% auto;
      background-position: center;
      width: 30px;
      height: 30px;
    }

    .swiper-button-prev::after {
      display: none;
    }

    .template-item {
      .digital-img,
      .postcard-img,
      .letter-img {
        border: 2px solid #a8b0c4;
      }
    }
    .selected-template {
      .digital-img,
      .postcard-img,
      .letter-img {
        border: 3px solid #04caae;
      }
    }

    .mySwiper {
      height: 100%;
      min-height: 450px;
      padding: 12px 24px;
      background-color: #fff;
      .swiper-wrapper {
        .swiper-slide {
          .slide-template {
            display: grid;
            grid-template-columns: ${(props: { isDigitalCampaign: boolean }) =>
              props.isDigitalCampaign ? 'repeat(2,1fr)' : 'repeat(3,1fr)'};
            grid-template-rows: ${(props: { isDigitalCampaign: boolean }) =>
              props.isDigitalCampaign ? 'repeat(2,200px)' : 'repeat(2,285px)'};
            grid-column-gap: 12px;
            grid-row-gap: 24px;
            height: 100%;
            > div {
              display: flex;
              justify-content: center;
              align-items: center;

              pointer-events: ${(props: { disabled: boolean }) => (props.disabled ? 'none' : 'initial')};

              .no-template {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #f0f0f0;
              }
            }
          }
          .height-auto {
            height: 100%;
          }
          .selected-template {
            padding: 2px;
            > div {
              .ant-image {
                height: 100%;
                img {
                  height: 100%;
                }
                .ant-image-mask {
                  display: none;
                }
              }
            }
          }
        }
      }
      .swiper-button-prev {
        left: -5px;
        &::after {
          font-size: 25px;
          line-height: 30px;
          color: #333333;
        }
      }
      .swiper-button-next {
        right: -5px;
        &::after {
          font-size: 25px;
          line-height: 30px;
          color: #333333;
        }
      }
    }
    .circle-play {
      position: absolute;
      width: 70px;
      height: 70px;
      cursor: pointer;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .ant-image {
        img {
          height: 70px !important;
        }
      }
    }
    .no-template {
      position: absolute;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      svg {
        font-size: 40px;
      }
    }
  `,
};

export default ScrollTemplateStyled;
