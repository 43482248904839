import { SelectObjType } from '@/components/select/mailing-list-select';
import { color } from '@/styles/theme';
import { POSTGRID_DEFAULT_VALUES } from '@/utils/constant';
import { formatObjectKeyToTitle } from '@/utils/utils';
import { PastClientTypeEnum } from '@goldfishcode/first-team-real-estate-sdk/libs/api/mailing/models';
import { SessionsVarsBody, SessionVar } from '@goldfishcode/first-team-real-estate-sdk/libs/api/postgrid/models';
import { MailingExtra } from '@goldfishcode/first-team-real-estate-sdk/libs/api/tools/variable/models';
import { Image, Radio, Tooltip } from 'antd';
import { omit } from 'lodash';
import React, { Dispatch, SetStateAction } from 'react';
import { LatestFilters, MailingData, MlsFilters, PC_PREFIX, SearchKeywords } from '../../helpers';
import { brokerLPVariables, VariableType } from '../../hooks/useFetchVariablesByType';
import { VariableList } from '../../styles/message-variable.style';
import HomeSearch from '../home-search';
import RenderVariables from '../render-variables';
import HomeSelect from '@/components/select/home-select';

export interface GetAutomateDataParams {
  status?: string | undefined;
  mailingListId?: string | string[] | undefined;
  mailingListLabel?: string | undefined;
  pastClientType?: PastClientTypeEnum | null;
}

export interface GetSelectedMailingDataParams {
  status?: string | undefined;
  mailingListId?: string | string[] | undefined;
  pastClientType?: PastClientTypeEnum | null;
  search?: string | undefined;
}

interface MessageVariableViewProps {
  isHandwritten?: boolean;
  onSelectVariable?: (value: string, isImageVar?: boolean) => void;
  variableType?: VariableType;
  mlsFilters: MlsFilters;
  automateFilterData: LatestFilters;
  handleGetSelectedMailingData: (
    variable: string,
    params: GetSelectedMailingDataParams,
  ) => Promise<
    {
      label: string;
      value: string;
    }[]
  >;
  handleGetAutomateData: (variable: string, params: GetAutomateDataParams) => Promise<void>;
  mailingData: MailingData;
  setMlsFilters: Dispatch<SetStateAction<MlsFilters>>;
  isSearching: boolean;
  processPCVariable: (v: string) => string;
  isNotAvailable: (value: string, availableVariable?: string[] | undefined) => boolean;
  availablePostgridVar: any;
  isEmailVariable: boolean;
  preprocessCopyValue: (variableKey: string, value: string) => string;
  onCopyVariable: (value: string) => void;
  getCurrentMailing: (variable: string) => MailingExtra | undefined;
  processMlsVariable: (mlsType: string, v: string) => string;
  searchKeywords: SearchKeywords;
  setSearchKeywords: Dispatch<SetStateAction<SearchKeywords>>;
  agentIdCampaign: string | undefined;
  allHiddenVars: string[];
  sortVarSection: (sectionVars: any) => any[][];
  postgridSessionVarWithImageVar: SessionsVarsBody & {
    image_variables: SessionVar;
  };
}

const MessageVariableView: React.FC<MessageVariableViewProps> = (props) => {
  const {
    onSelectVariable,
    variableType = VariableType.EMAIL,
    isHandwritten,
    mlsFilters,
    automateFilterData,
    handleGetSelectedMailingData,
    handleGetAutomateData,
    mailingData,
    setMlsFilters,
    isSearching,
    processPCVariable,
    isNotAvailable,
    availablePostgridVar,
    isEmailVariable,
    preprocessCopyValue,
    onCopyVariable,
    getCurrentMailing,
    processMlsVariable,
    searchKeywords,
    setSearchKeywords,
    agentIdCampaign,
    allHiddenVars,
    sortVarSection,
    postgridSessionVarWithImageVar,
  } = props;

  const renderPastClientSessionVariable = (variable: string, pastClientSessionData: string[], index) => {
    if (!['past_clients'].includes(variable)) return null;

    const pcID = 'pc_variables';

    const useManualSelect = mlsFilters[PC_PREFIX]?.useManualSelect;
    const mailingListValue = useManualSelect
      ? mlsFilters[PC_PREFIX]?.mailingListId || ''
      : automateFilterData[PC_PREFIX]?.mailingListId || '';

    const mailingListSearchValue = useManualSelect
      ? searchKeywords[PC_PREFIX]?.mailingList
      : searchKeywords[PC_PREFIX]?.mailingList;
    const mlsStatusValue = useManualSelect
      ? mlsFilters[PC_PREFIX]?.status || ''
      : automateFilterData[PC_PREFIX]?.status || '';

    const handleMailingListChange = useManualSelect
      ? (value, option) => {
          handleGetSelectedMailingData(PC_PREFIX, {
            mailingListId: value,
            pastClientType: (option as SelectObjType)?.pastClientType,
          });
        }
      : (value, option) => {
          handleGetAutomateData(PC_PREFIX, {
            mailingListId: value,
            mailingListLabel: (option as SelectObjType)?.label,
            pastClientType: (option as SelectObjType)?.pastClientType,
          });
        };

    const handleMailingListSearch = useManualSelect
      ? (value) => {
          setSearchKeywords((prev) => ({
            ...prev,
            [PC_PREFIX]: { ...prev[PC_PREFIX], mailingList: value },
          }));
        }
      : (value) => {
          setSearchKeywords((prev) => ({
            ...prev,
            [PC_PREFIX]: { ...prev[PC_PREFIX], mailingList: value },
          }));
        };

    const handleMLSStatusChange = useManualSelect
      ? (value: string) => handleGetSelectedMailingData(PC_PREFIX, { status: value })
      : (value: string) => handleGetAutomateData(PC_PREFIX, { status: value });

    const isShowSearchAddress =
      mlsFilters[PC_PREFIX]?.status && mlsFilters[PC_PREFIX]?.mailingListId && mlsFilters[PC_PREFIX]?.useManualSelect;

    const isNormalShowVariables = !mlsFilters[PC_PREFIX]?.useManualSelect
      ? automateFilterData[PC_PREFIX]?.status && automateFilterData[PC_PREFIX]?.mailingListId
      : mlsFilters[PC_PREFIX]?.status && mlsFilters[PC_PREFIX]?.mailing?.value;

    const isImageShowVariables =
      ((!isHandwritten &&
        mlsFilters[PC_PREFIX]?.useManualSelect &&
        mlsFilters[PC_PREFIX]?.mailing?.value &&
        mailingData[PC_PREFIX]?.data?.length) ||
        automateFilterData[PC_PREFIX]?.mailing?.mls_media?.length) &&
      variableType !== VariableType.SMS;

    return (
      <div className="variable-session child">
        <div id={pcID}>
          <div className="mls-filters">
            <HomeSearch
              containerClassName="mls-filters-upper"
              isPastClient
              isMyListing={false}
              isExcludeMyListing={true}
              isHandwritten={isHandwritten}
              variableType={variableType}
              agentIdCampaign={agentIdCampaign}
              mailingListFieldName="mailing-list"
              handleMailingListChange={handleMailingListChange}
              mailingListValue={mailingListValue}
              handleMailingListSearch={handleMailingListSearch}
              mailingListSearchValue={mailingListSearchValue}
              parentId={`mls_status_variable_${index}`}
              mlsStatusValue={mlsStatusValue}
              handleMLSStatusChange={handleMLSStatusChange}
            />

            {isShowSearchAddress && (
              <HomeSelect
                className="field mls-selector"
                placeholder="Search For Address"
                value={mlsFilters[PC_PREFIX]?.mailing?.value ? mlsFilters[PC_PREFIX]?.mailing : undefined}
                fetchOptions={(value: string) => handleGetSelectedMailingData(PC_PREFIX, { search: value })}
                defaultOptions={mailingData[PC_PREFIX]?.data?.map((mailing) => ({
                  label: mailing.address || '',
                  value: mailing.id,
                }))}
                onChange={(data) => {
                  const option = Array.isArray(data) ? data[0] : data;
                  setMlsFilters((prev) => ({
                    ...prev,
                    [PC_PREFIX]: { ...prev[PC_PREFIX], mailing: { ...option } },
                  }));
                }}
                getPopupContainer={() => document.getElementById(pcID) as HTMLElement}
                loading={isSearching}
                allowClear
                showSearch
              />
            )}
          </div>
          {isNormalShowVariables ? (
            <RenderVariables
              listVariables={pastClientSessionData.map((v: string) => ({
                value: processPCVariable(v),
                label: processPCVariable(v),
              }))}
              variableKey={PC_PREFIX}
              variableType={variableType}
              isNotAvailable={isNotAvailable}
              availablePostgridVar={availablePostgridVar}
              isEmailVariable={isEmailVariable}
              preprocessCopyValue={preprocessCopyValue}
              onSelectVariable={onSelectVariable}
              onCopyVariable={onCopyVariable}
              getCurrentMailing={getCurrentMailing}
              isHandwritten={isHandwritten}
            />
          ) : null}

          {isImageShowVariables && isNormalShowVariables ? (
            <Image.PreviewGroup>
              {/* <p>{mlsFilters[variable]?.mailing?.value}</p> */}
              <div className="preview-wrapper">
                {getCurrentMailing(PC_PREFIX)
                  ?.mls_media?.slice(0, 6)
                  .map((media, index) => (
                    <Image
                      key={media.Id}
                      src={media.MediaURL}
                      width={80}
                      height={80}
                      preview={{
                        mask: (
                          <div>
                            <div>Preview</div>
                            <div>&</div>
                            <div>Copy</div>
                          </div>
                        ),
                      }}
                      fallback={POSTGRID_DEFAULT_VALUES.NO_DATA_PREVIEW_ONLY_IMAGE}
                      onClick={() => {
                        const value = processMlsVariable(PC_PREFIX, `image_${index + 1}`);
                        const copiedValue = PC_PREFIX ? preprocessCopyValue(PC_PREFIX, value) : value;
                        isEmailVariable && onSelectVariable
                          ? onSelectVariable?.(copiedValue, true)
                          : onCopyVariable(copiedValue);
                      }}
                    />
                  ))}
              </div>
            </Image.PreviewGroup>
          ) : null}
        </div>
      </div>
    );
  };

  const renderMLSSessionVariable = (isHasMLSStatus: boolean, variable: string, variableData: any, index: number) => {
    if (!isHasMLSStatus) return null;
    const isMyListing = ['my_listings'].includes(variable);
    const useManualSelect = mlsFilters[variable]?.useManualSelect;
    const mailingListValue = useManualSelect
      ? mlsFilters[variable]?.mailingListId || ''
      : automateFilterData[variable]?.mailingListId || '';

    const mailingListSearchValue = useManualSelect
      ? searchKeywords[variable]?.mailingList
      : searchKeywords[variable]?.mailingList;
    const mlsStatusValue = useManualSelect
      ? mlsFilters[variable]?.status || ''
      : automateFilterData[variable]?.status || '';

    const handleMailingListChange = useManualSelect
      ? (value) => handleGetSelectedMailingData(variable, { mailingListId: value })
      : (value, options) => {
          handleGetAutomateData(variable, {
            mailingListId: value,
            mailingListLabel: (options as SelectObjType)?.label,
          });
        };

    const handleMailingListSearch = useManualSelect
      ? (value) => {
          setSearchKeywords((prev) => ({
            ...prev,
            [variable]: { ...prev[variable], mailingList: value },
          }));
        }
      : (value) => {
          setSearchKeywords((prev) => ({
            ...prev,
            [variable]: { ...prev[variable], mailingList: value },
          }));
        };

    const handleMLSStatusChange = useManualSelect
      ? (value: string) => handleGetSelectedMailingData(variable, { status: value })
      : (value: string) => handleGetAutomateData(variable, { status: value });

    const isShowSearchAddress =
      mlsFilters[variable]?.status && mlsFilters[variable]?.mailingListId && mlsFilters[variable]?.useManualSelect;

    const isNormalShowVariables = !mlsFilters[variable]?.useManualSelect
      ? automateFilterData[variable]?.status && automateFilterData[variable]?.mailingListId
      : mlsFilters[variable]?.status && mlsFilters[variable]?.mailingListId && mlsFilters[variable]?.mailing?.value;

    const isImageShowVariables =
      !isHandwritten &&
      ((mlsFilters[variable]?.mailing?.value && mailingData[variable]?.data?.length) ||
        automateFilterData[variable]?.mailing?.mls_media?.length) &&
      variableType !== VariableType.SMS;

    return (
      <div className="variable-session child" id={`mls_status_variable_${index}`}>
        <div className="mls-filters">
          <HomeSearch
            containerClassName="mls-filters-upper"
            isMyListing={isMyListing}
            isExcludeMyListing={!isMyListing}
            isHandwritten={isHandwritten}
            variableType={variableType}
            agentIdCampaign={agentIdCampaign}
            mailingListFieldName="mailing-list"
            handleMailingListChange={handleMailingListChange}
            mailingListValue={mailingListValue}
            handleMailingListSearch={handleMailingListSearch}
            mailingListSearchValue={mailingListSearchValue}
            parentId={`mls_status_variable_${index}`}
            mlsStatusValue={mlsStatusValue}
            handleMLSStatusChange={handleMLSStatusChange}
          />

          {isShowSearchAddress && (
            <HomeSelect
              className="field mls-selector"
              placeholder="Search For Address"
              value={mlsFilters[variable]?.mailing?.value ? mlsFilters[variable]?.mailing : undefined}
              fetchOptions={(value: string) => handleGetSelectedMailingData(variable, { search: value })}
              defaultOptions={mailingData[variable]?.data?.map((mailing) => ({
                label: mailing.address || '',
                value: mailing.id,
              }))}
              onChange={(data) => {
                const option = Array.isArray(data) ? data[0] : data;
                setMlsFilters((prev) => ({
                  ...prev,
                  [variable]: { ...prev[variable], mailing: { ...option } },
                }));
              }}
              getPopupContainer={() => document.getElementById(`mls_status_variable_${index}`) as HTMLElement}
              loading={isSearching}
              allowClear
              showSearch
            />
          )}
        </div>

        {isNormalShowVariables ? (
          <div className="mls-status-list">
            <RenderVariables
              listVariables={(variableData['mls_status'] as string[])
                ?.filter((property: string) => !allHiddenVars.includes(property))
                ?.map((v: string) => ({
                  value: processMlsVariable(variable, v),
                  label: processMlsVariable(variable, v),
                }))}
              variableKey={variable}
              variableType={variableType}
              isNotAvailable={isNotAvailable}
              availablePostgridVar={availablePostgridVar}
              isEmailVariable={isEmailVariable}
              preprocessCopyValue={preprocessCopyValue}
              onSelectVariable={onSelectVariable}
              onCopyVariable={onCopyVariable}
              getCurrentMailing={getCurrentMailing}
              isHandwritten={isHandwritten}
            />
          </div>
        ) : null}

        {isImageShowVariables && isNormalShowVariables && (
          <Image.PreviewGroup>
            {/* <p>{mlsFilters[variable]?.mailing?.value}</p> */}
            <div className="preview-wrapper">
              {getCurrentMailing(variable)
                ?.mls_media?.slice(0, 6)
                .map((media, index) => (
                  <Image
                    key={media.Id}
                    src={media.MediaURL}
                    width={80}
                    height={80}
                    preview={{
                      mask: (
                        <div>
                          <div>Preview</div>
                          <div>&</div>
                          <div>Copy</div>
                        </div>
                      ),
                    }}
                    fallback={POSTGRID_DEFAULT_VALUES.NO_DATA_PREVIEW_ONLY_IMAGE}
                    onClick={() => {
                      const value = processMlsVariable(variable, `image_${index + 1}`);
                      const copiedValue = variable ? preprocessCopyValue(variable, value) : value;
                      isEmailVariable && onSelectVariable
                        ? onSelectVariable?.(copiedValue, true)
                        : onCopyVariable(copiedValue);
                    }}
                  />
                ))}
            </div>
          </Image.PreviewGroup>
        )}
      </div>
    );
  };

  const renderSessionVariable = (type: VariableType) => {
    return (
      <VariableList>
        {sortVarSection(
          type === VariableType.SMS || isHandwritten
            ? omit(postgridSessionVarWithImageVar, 'image_variables')
            : postgridSessionVarWithImageVar,
        ).map(([variable, variableData], index) => {
          const isMyListing = ['my_listings'].includes(variable);
          const isHasMLSStatus = !!variableData?.['mls_status']?.length;
          const isPastClient = ['past_clients'].includes(variable);
          const storeKey = isPastClient ? PC_PREFIX : variable;
          const useManualSelect = !!mlsFilters[storeKey]?.useManualSelect;

          const pcType =
            (useManualSelect
              ? mlsFilters?.[storeKey]?.pastClientType?.toLowerCase()
              : automateFilterData?.[storeKey]?.pastClientType?.toLowerCase()) || '';
          const pcVariableData = variableData?.[pcType]?.original_vars || [];

          return (
            !['mls_status'].includes(variable) && (
              <div className="variable-session" key={variable}>
                <p className="title">
                  {variable === 'listing_of_my_mailing_list'
                    ? 'My Listings: Pre-Drawn Lists'
                    : formatObjectKeyToTitle(variable.replaceAll('mls', 'MLS'))}
                </p>
                {(isMyListing || isHasMLSStatus || isPastClient) && (
                  <div className="auto-latest">
                    <Radio.Group value={useManualSelect} size="large">
                      <Tooltip
                        key={variable}
                        title={'Automatically pulls the last MLS Status from the selected Mailing List'}
                        color={color.WHITE}
                        overlayStyle={{
                          border: 'none',
                        }}
                        overlayInnerStyle={{
                          fontSize: '10px',
                          fontWeight: 600,
                          color: color.DARKBLUE,
                          borderRadius: '4px',
                          border: 'none',
                          transform: 'translateY(2px)',
                        }}
                        align={{ offset: [0, 4] }}
                        destroyTooltipOnHide={{ keepParent: false }}
                        autoAdjustOverflow={false}
                        mouseLeaveDelay={0}
                        mouseEnterDelay={0}
                      >
                        <Radio.Button
                          value={false}
                          onChange={(e) =>
                            setMlsFilters((prev) => ({
                              ...prev,
                              [storeKey]: { ...prev[storeKey], useManualSelect: e.target.value },
                            }))
                          }
                        >
                          Automate
                        </Radio.Button>
                      </Tooltip>
                      <Radio.Button
                        value={true}
                        onChange={(e) =>
                          setMlsFilters((prev) => ({
                            ...prev,
                            [storeKey]: { ...prev[storeKey], useManualSelect: e.target.value },
                          }))
                        }
                      >
                        Select Address
                      </Radio.Button>
                    </Radio.Group>
                  </div>
                )}

                <div className="list-var">
                  <RenderVariables
                    listVariables={variableData['original_vars']
                      ?.filter((item: string) => !allHiddenVars.includes(item))
                      .map((v: string) => ({ value: v, label: v }))}
                    variableType={variableType}
                    isNotAvailable={isNotAvailable}
                    availablePostgridVar={availablePostgridVar}
                    isEmailVariable={isEmailVariable}
                    onSelectVariable={onSelectVariable}
                    onCopyVariable={onCopyVariable}
                    getCurrentMailing={getCurrentMailing}
                    isHandwritten={isHandwritten}
                    sessionName={variable}
                  />
                </div>

                {renderPastClientSessionVariable(variable, pcVariableData, index)}
                {renderMLSSessionVariable(isHasMLSStatus, variable, variableData, index)}
              </div>
            )
          );
        })}
      </VariableList>
    );
  };

  switch (variableType) {
    case VariableType.POSTGRID:
    case VariableType.EMAIL:
    case VariableType.SMS:
      return renderSessionVariable(variableType);
    case VariableType.BROKER_LP:
      return (
        <RenderVariables
          listVariables={brokerLPVariables}
          variableType={variableType}
          isNotAvailable={isNotAvailable}
          availablePostgridVar={availablePostgridVar}
          isEmailVariable={isEmailVariable}
          preprocessCopyValue={preprocessCopyValue}
          onSelectVariable={onSelectVariable}
          onCopyVariable={onCopyVariable}
          getCurrentMailing={getCurrentMailing}
          isHandwritten={isHandwritten}
        />
      );
    default:
      return <></>;
  }
};

export default MessageVariableView;
