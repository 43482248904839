import styled from '@emotion/styled';

export const VariableList = styled.div`
  width: 100%;

  .variable-session {
    padding: 5px 0;
    width: 100%; // fit-content;

    &.child {
      position: relative;
      margin-left: 8px;
      padding-left: 8px;
      border-left: 1px solid #aca3b2;
    }

    .auto-latest {
      /* display: flex;
      align-items: center;
      gap: 6px;
      font-size: 14px;
      font-weight: 400;
      padding-right: 8px;

      p {
        font-size: 14px !important;
        font-weight: 400 !important;
      } */

      .ant-radio-button-wrapper {
        font-size: 14px;
        font-weight: 600;
        text-align: center;
        background-color: transparent;
        color: #ffff !important;
        border-color: #04caae !important;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        @media only screen and (min-width: 2000px) {
          min-width: 184px;
        }
        &::hover,
        &::focus,
        &::active {
          border-color: #04caae;
          color: #ffff !important;
        }
        &::before {
          display: none;
        }
        &.ant-radio-button-wrapper-checked {
          background-color: #04caae;

          &::hover,
          &::focus,
          &::active {
            border-color: #04caae;
            color: #04caae !important;
          }
        }
      }
    }

    .title,
    .sub-title,
    .sub-category,
    .pc-sub-title {
      font-weight: 700;
      margin-bottom: 5px;
      font-family: FiraSans;
      font-size: 20px;
      font-style: italic;
    }

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .sub-title,
    .sub-category {
      font-size: 12px;
      font-weight: 600;
    }
    .pc-sub-title {
      margin-top: -5px;
      font-size: 14px;
    }
    .sub-category {
      text-align: left;
      font-size: 14px;
      margin-left: 10px;
    }
    .variable-list {
      display: flex;
      flex-wrap: wrap;
      gap: 2px;
      margin-bottom: 5px;
      padding: 5px;
    }

    .list-var {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .pc-filters {
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding-right: 16px;

    .mls-selector {
      width: 100%;
    }
  }

  .mls-filters {
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding-right: 16px;

    .mls-filters-upper {
      display: flex;
      gap: 8px;
      & > div {
        flex: 1;
      }
    }

    .mls-filters-lower {
      .mls-selector {
        width: 100%;
      }
    }
  }

  .preview-wrapper {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    padding-right: 8px;

    img {
      border-radius: 6px;
    }
  }
`;

export const VariableTag = styled.span`
  font-size: 10px;
  background-color: #eaeaea;
  padding: 4px;
  margin: 1px;
  border: 1px solid #eaeaea;
  border-radius: 20px;
  font-weight: 700;
  color: #150056;
  cursor: pointer;
  max-width: 100%;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  &:hover {
    border: 1px solid #fff;
    background-color: #fff;
  }

  &.not-available {
    background-color: #cccc !important;
    border-color: #cccc !important;
    color: #150056 !important;
    cursor: not-allowed !important;
  }
  &.image-variable {
    color: #fff;
    background-color: #04caae;
    border-color: #04caae;
  }
  &.hide-image-variable {
    display: none !important;
  }
  &.disable-image-var {
    background-color: #cccc !important;
    border-color: #cccc !important;
    color: #150056 !important;
    cursor: not-allowed !important;
  }
`;
