import { EyeOutlined, FileImageOutlined } from '@ant-design/icons';
import React from 'react';

import {
  DigitalAdTypes,
  DigitalCampaignTemplate,
} from '@goldfishcode/first-team-real-estate-sdk/libs/api/postalytics/models';
import { Image, message } from 'antd';
import ReactPlayer from 'react-player';
import CircelPlay from '@/assets/icons/circle-play.svg';

import styled from '@emotion/styled';
import {
  PostGridTemplate,
  PostgridTemplateType,
  TemplateViewSide,
} from '@goldfishcode/first-team-real-estate-sdk/libs/api/postgrid/models';
import { cloneDeep } from 'lodash';
import { CampaignTypeEnum, TemplateITemEnum } from '@/utils/enum';
import 'swiper/swiper-bundle.css';
import RenderHTML from './RenderHTML';
import classNames from 'classnames';
import { useInView } from 'react-intersection-observer';
import Skeleton from 'react-loading-skeleton';

import { FaLock } from 'react-icons/fa';
import useDisableBySubscription from '@/hooks/useDisableBySubscription';
import { css } from '@emotion/react';
import { EmailSaveDataType } from '@/components/custom-modals/modal-editor-email';
import isEqual from 'react-fast-compare';
import useScrollTemplate from './hooks/useScrollTemplate';
import { useCampaignManagerStore } from '../../hooks/useCampaignManager';
import { useShallow } from 'zustand/react/shallow';
import AppButtonV2 from '@/components/AppButtonV2';
import { color } from '@/styles/theme';

interface TemplateItemProps {
  type: TemplateITemEnum;
  template: PostGridTemplate | DigitalCampaignTemplate | EmailSaveDataType | any;
  isDigitalCampaign?: boolean;
  disabled?: boolean;
  setDataPreviewTemplate: (data: any) => void;
  openFallbacks?: React.Dispatch<React.SetStateAction<boolean>>;
  campaignType: CampaignTypeEnum;
}

const TemplateItem: React.FC<TemplateItemProps> = (props) => {
  const { type, template, isDigitalCampaign = false, campaignType, setDataPreviewTemplate } = props;
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.5, rootMargin: '0px 0px -100px 0px' });
  const { isDisableBySubscription } = useDisableBySubscription();
  const { agentIdCampaign } = useCampaignManagerStore(
    useShallow((state) => ({
      agentIdCampaign: state.agentIdCampaign,
    })),
  );
  const { handleGetPostgridTemplateDetail } = useScrollTemplate();

  const handleSetPostgridPreviewData = async (side: TemplateViewSide) => {
    let templateData;
    message.loading('Fetching your template asset...', 1000);

    try {
      const isPostgrid = type && [TemplateITemEnum.LETTER, TemplateITemEnum.POSTCARD].includes(type);
      const detailTemplate = isPostgrid
        ? await handleGetPostgridTemplateDetail(
            template.id,
            false,
            template?.mls_data,
            template?.mailing_list_data,
            agentIdCampaign,
          )
        : template;

      templateData = detailTemplate;
    } catch (error) {
      templateData = template;
    }

    setDataPreviewTemplate({
      visible: true,
      data: {
        ...templateData,
        side,
      },
      type: type,
    });
  };

  const renderContent = ({ digitalTemplate }: any) => {
    if (isDigitalCampaign && digitalTemplate) {
      return (
        <>
          {isDisableBySubscription ? (
            <>
              <div className="lock">
                <FaLock />
              </div>
            </>
          ) : null}
          <div
            className={classNames('digital-img', {
              'digital-img-blur': isDisableBySubscription,
            })}
          >
            {digitalTemplate?.digital_ad_type === DigitalAdTypes.DIGITAL_AD_VIDEO ? (
              digitalTemplate?.thumbnail ? (
                <>
                  <Image
                    title={digitalTemplate?.name}
                    src={digitalTemplate?.thumbnail}
                    preview={false}
                    style={{ height: '100%' }}
                  />
                  {digitalTemplate?.digital_ad_type === DigitalAdTypes.DIGITAL_AD_VIDEO && digitalTemplate?.media_file && (
                    <div
                      className="circle-play"
                      onClick={(e) => {
                        e.stopPropagation();
                        setDataPreviewTemplate &&
                          setDataPreviewTemplate({
                            visible: true,
                            data: {
                              ...digitalTemplate,
                            },
                            type: type,
                          });
                      }}
                    >
                      <Image src={CircelPlay} preview={false} />
                    </div>
                  )}
                </>
              ) : (
                <>
                  <ReactPlayer url={digitalTemplate?.media_file} width="100%" height="100%" autoplay controls={false} />
                  {digitalTemplate?.digital_ad_type === DigitalAdTypes.DIGITAL_AD_VIDEO && digitalTemplate?.media_file && (
                    <div
                      className="circle-play"
                      onClick={(e) => {
                        e.stopPropagation();
                        setDataPreviewTemplate &&
                          setDataPreviewTemplate({
                            visible: true,
                            data: {
                              ...digitalTemplate,
                            },
                            type: type,
                          });
                      }}
                    >
                      <Image src={CircelPlay} preview={false} />
                    </div>
                  )}
                </>
              )
            ) : digitalTemplate?.digital_ad_type === DigitalAdTypes.DIGITAL_AD_IMAGE ? (
              <Image
                title={digitalTemplate?.name}
                src={digitalTemplate?.thumbnail || digitalTemplate?.media_file}
                preview={false}
              />
            ) : (
              <div className="no-template">
                <FileImageOutlined />
              </div>
            )}
            {digitalTemplate?.name && (
              <p className="preview name" title={digitalTemplate?.name}>
                {digitalTemplate?.name}
              </p>
            )}
            {!isDisableBySubscription &&
              digitalTemplate?.digital_ad_type !== DigitalAdTypes.DIGITAL_AD_VIDEO &&
              (digitalTemplate?.thumbnail || digitalTemplate?.media_file) && (
                <p
                  className="preview preview-eye preview-digital"
                  onClick={(e) => {
                    setDataPreviewTemplate &&
                      setDataPreviewTemplate({
                        visible: true,
                        data: {
                          ...digitalTemplate,
                        },
                        type: type,
                      });
                    e.stopPropagation();
                  }}
                >
                  <EyeOutlined />
                </p>
              )}
          </div>
        </>
      );
    } else if (campaignType === CampaignTypeEnum.PHYSICAL) {
      const campaignTemplate: PostGridTemplate = cloneDeep(template) as PostGridTemplate;
      if (campaignTemplate.type === PostgridTemplateType.HANDWRITTEN) {
        // TODO: Handle this case in future if have. For now, not to do anything here
      }
      const isShowLock = (campaignTemplate?.is_premium && !campaignTemplate?.is_purchased) || isDisableBySubscription;
      return (
        <>
          {isShowLock ? (
            <>
              <div className="lock">
                <FaLock />
              </div>
              {!isDisableBySubscription && (
                <div
                  className="cost"
                  title={
                    campaignTemplate.home_credit > 0 && campaignTemplate.credit === 0
                      ? `${campaignTemplate.home_credit} Home Credit(s)`
                      : `${campaignTemplate.credit} Campaign Credit(s)`
                  }
                >
                  {campaignTemplate.home_credit > 0 && campaignTemplate.credit === 0 ? (
                    <p>{`${campaignTemplate.home_credit} Home Credit(s)`}</p>
                  ) : (
                    <p>{`${campaignTemplate.credit} Campaign Credit(s)`}</p>
                  )}
                </div>
              )}
            </>
          ) : null}
          {campaignTemplate?.preview_front_image?.startsWith('https') || campaignTemplate?.front_template ? (
            <div
              className={classNames('postcard-img', {
                'letter-img': type === TemplateITemEnum.LETTER,
              })}
            >
              <RenderHTML
                scaleMultiplier={0.5}
                id={(campaignTemplate as any)?.id}
                htmlString={
                  (campaignTemplate.type === PostgridTemplateType.HANDWRITTEN
                    ? campaignTemplate?.message_template
                    : campaignTemplate?.preview_front_image?.startsWith('https')
                    ? `<img src="${campaignTemplate?.preview_front_image}"
                    style="
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                    "
                    />`
                    : campaignTemplate?.front_template) || ''
                }
                type={type}
                className={classNames('campaign-template', {
                  'premium-img':
                    (campaignTemplate?.is_premium && !campaignTemplate?.is_purchased) || isDisableBySubscription,
                })}
                isPreviewRawTemplate={true}
                isImageThumbnail={campaignTemplate?.preview_front_image?.startsWith('https')}
              />
            </div>
          ) : (
            <div className="postcard-img">
              <div
                className={classNames('no-template', {
                  'no-template-postcard': type === TemplateITemEnum.POSTCARD,
                })}
              >
                <FileImageOutlined />
              </div>
            </div>
          )}
          {campaignTemplate?.name && (
            <p className="preview name" title={campaignTemplate?.name}>
              {campaignTemplate?.name}
            </p>
          )}
          {!isShowLock &&
            (type === TemplateITemEnum.POSTCARD ? (
              <div className="preview-btn-group">
                <AppButtonV2
                  size="small"
                  color="Pin"
                  width={50}
                  uppercase={false}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSetPostgridPreviewData(TemplateViewSide.FRONT);
                  }}
                >
                  Front
                </AppButtonV2>
                <AppButtonV2
                  size="small"
                  color="Pin"
                  width={50}
                  uppercase={false}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSetPostgridPreviewData(TemplateViewSide.BACK);
                  }}
                >
                  Back
                </AppButtonV2>
              </div>
            ) : (
              <div
                className="preview-eye"
                onClick={(e) => {
                  e.stopPropagation();
                  handleSetPostgridPreviewData(TemplateViewSide.FRONT);
                }}
              >
                <EyeOutlined />
              </div>
            ))}
        </>
      );
    } else if (campaignType === CampaignTypeEnum.EMAIL) {
      const emailTemplate: EmailSaveDataType = cloneDeep(template);
      const htmlString = template?.htmlString;
      const isShowLock = isDisableBySubscription;
      return (
        <>
          {isShowLock ? (
            <>
              <div className="lock">
                <FaLock />
              </div>
            </>
          ) : null}
          <div
            className={classNames('postcard-img', {
              'letter-img': type === TemplateITemEnum.EMAIL,
            })}
          >
            <RenderHTML
              scaleMultiplier={0.5}
              id={(emailTemplate as any)?.id}
              htmlString={htmlString}
              type={type}
              className={classNames('campaign-template')}
              isPreviewRawTemplate={true}
            />
          </div>
          {!isShowLock && (
            <div
              className="preview-eye"
              onClick={(e) => {
                e.stopPropagation();
                setDataPreviewTemplate({
                  visible: true,
                  data: {
                    ...emailTemplate,
                    side: TemplateViewSide.FRONT,
                    htmlString: htmlString,
                  },
                  type: type,
                });
              }}
            >
              <EyeOutlined />
            </div>
          )}
          {emailTemplate?.name && (
            <p className="preview name" title={emailTemplate?.name}>
              {emailTemplate?.name}
            </p>
          )}
        </>
      );
    }
  };

  const renderCTR = ({ digitalTemplate }) => {
    const isInUsed = digitalTemplate?.is_in_use || false;
    const isMinCTR =
      typeof digitalTemplate?.click_through_rate_min === 'number' &&
      typeof digitalTemplate?.click_through_rate_max === 'number' &&
      digitalTemplate?.click_through_rate_min >= 0 &&
      digitalTemplate?.click_through_rate_min === digitalTemplate?.click_through_rate_max;
    return (
      <div className="CTR-content">
        {!isInUsed && (
          <p>
            Click-through rate: <span>No data collected yet</span>
          </p>
        )}
        {isInUsed &&
          (isMinCTR ? (
            <p>
              Min Click-through rate: <span>{digitalTemplate?.click_through_rate_min} %</span>
            </p>
          ) : (
            <p>
              Click-through rate:{' '}
              <span>{`${digitalTemplate?.click_through_rate_min || 0} % - ${
                digitalTemplate?.click_through_rate_max || 0
              } %`}</span>
            </p>
          ))}

        <p>
          QR codes scanned: <span>{digitalTemplate?.qr_code_scanned || 0}</span>
        </p>
      </div>
    );
  };

  const renderMinInteraction = ({ postgridTemplate }) => {
    const isInUsed = postgridTemplate?.is_in_use || false;
    const isMinInteraction =
      typeof postgridTemplate?.min_interaction_rate === 'number' &&
      typeof postgridTemplate?.max_interaction_rate === 'number' &&
      postgridTemplate?.min_interaction_rate >= 0 &&
      postgridTemplate?.min_interaction_rate === postgridTemplate?.max_interaction_rate;
    return (
      <div className="min-interaction CTR-content">
        {!isInUsed && (
          <>
            <p className="ir-title">Interaction rate:</p>
            <p>No data collected yet</p>
          </>
        )}
        {isInUsed &&
          (isMinInteraction ? (
            <>
              <p className="ir-title">Min Interaction rate:</p>
              <p>{postgridTemplate?.min_interaction_rate} %</p>
            </>
          ) : (
            <>
              <p className="ir-title">Interaction range:</p>
              <p>{`${postgridTemplate?.min_interaction_rate || 0} % - ${
                postgridTemplate?.max_interaction_rate || 0
              } %`}</p>
            </>
          ))}
      </div>
    );
  };

  if (isDigitalCampaign) {
    const digitalTemplate: DigitalCampaignTemplate = cloneDeep(template) as DigitalCampaignTemplate;
    const templateType =
      type === TemplateITemEnum.DIGITAL_AD_VIDEO
        ? TemplateITemEnum.DIGITAL_AD_VIDEO
        : TemplateITemEnum.DIGITAL_AD_IMAGE;
    return (
      <TemplateITemStyled ref={ref} className={``} type={templateType} key={digitalTemplate?.id}>
        {inView ? renderContent({ digitalTemplate }) : <Skeleton width={270} height={140} />}
        {inView ? (
          renderCTR({ digitalTemplate })
        ) : (
          <>
            <Skeleton width={270} height={10} />
            <Skeleton
              width={270}
              height={10}
              style={{
                transform: 'translateY(-8px)',
              }}
            />
          </>
        )}
      </TemplateITemStyled>
    );
  }
  const postgridTemplate: PostGridTemplate = cloneDeep(template) as PostGridTemplate;
  const isShowLock =
    type !== TemplateITemEnum.EMAIL && ((template?.is_premium && !template?.is_purchased) || isDisableBySubscription);
  return (
    <TemplateITemStyled
      ref={ref}
      key={(postgridTemplate as any)?.id}
      type={type}
      isShowLock={isShowLock}
      className="template"
    >
      {inView ? renderContent({}) : <Skeleton width={190} height={245} />}
      {campaignType !== CampaignTypeEnum.EMAIL && template?.type !== CampaignTypeEnum.HANDWRITTEN ? (
        inView ? (
          renderMinInteraction({ postgridTemplate })
        ) : (
          <>
            <Skeleton width={190} height={10} />
            <Skeleton
              width={190}
              height={10}
              style={{
                transform: 'translateY(-8px)',
              }}
            />
          </>
        )
      ) : null}
    </TemplateITemStyled>
  );
};

export default React.memo(TemplateItem, isEqual);

const TemplateITemStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
  width: 100%;
  color: ${color.BLACK};
  .preview-eye {
    position: absolute;

    ${(props: { type: TemplateITemEnum }) =>
      props.type === TemplateITemEnum.LETTER
        ? css`
            bottom: 150px;
          `
        : props.type === TemplateITemEnum.DIGITAL_AD_IMAGE
        ? css`
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          `
        : ''}

    padding: 0 5px;
    background: #615b59;
    opacity: 0;
    font-size: 22px;
    border-radius: 15px;
    width: 50px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    &:hover {
      cursor: pointer;
    }
  }
  .no-template-postcard {
    max-height: 50%;
    top: unset !important;
    min-height: unset !important;
  }

  &:hover {
    .preview-eye {
      opacity: 0.8;
    }
    .preview {
      opacity: 1;
    }
  }
  .email-img,
  .postcard-img,
  .digital-img {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    &:hover {
      .campaign-template,
      .ant-image {
        opacity: 0.9;
      }
    }
  }
  .postcard-img {
    display: flex;
    align-items: center;
  }
  .template-item {
    &.preview {
      opacity: 1;
    }
  }
  .preview {
    position: absolute;
    font-size: 14px;
    text-align: center;
    /* background: rgba(0, 0, 0, 0.5); */
    background: #615b59;
    opacity: 0;
    text-decoration: underline;
    color: #fff;
    width: 100%;
  }
  .name {
    bottom: ${(props: { type: TemplateITemEnum; isShowLock?: boolean }) =>
      props.isShowLock
        ? '53px'
        : props.type === TemplateITemEnum.POSTCARD
        ? '34%'
        : props.type === TemplateITemEnum.LETTER
        ? '50px'
        : '3px'};
    opacity: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .letter-img {
    height: 100%;
    width: 100%;
    .ant-image {
      height: 100%;
      width: 100%;
      .ant-image-img {
        height: 100%;
      }
    }
  }

  .digital-img {
    max-height: 100%;
    width: 100%;
    text-align: center;
    .ant-image,
    .ant-image-img {
      height: 100%;
    }
    .preview-digital {
      width: 50px;
    }
  }
  .digital-img-blur {
    & > div {
      filter: blur(4px);
    }
  }

  .ant-image {
    .ant-image-mask {
      display: none;
    }
  }

  .preview-btn-group {
    display: flex;
    position: absolute;
    bottom: 55px;
    gap: 7px;
    .preview-btn {
      transition: all 0.5s;
      color: #fff;
      background: #04caae;
      height: 30px;
      font-size: 12px;
      text-align: center;
      cursor: pointer;
      display: flex;
      align-items: center;
      font-weight: 700;
    }
  }

  .info {
    text-align: left;
    font-size: 14px;
    width: 100%;
  }

  .lock,
  .cost {
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 12px;
  }
  .lock {
    position: absolute;
    top: ${(props) =>
      [TemplateITemEnum.DIGITAL_AD_VIDEO, TemplateITemEnum.DIGITAL_AD_IMAGE].includes(props.type)
        ? '40px'
        : [TemplateITemEnum.EMAIL].includes(props.type)
        ? '105px'
        : '80px'};
    svg {
      stroke: #615b59 !important;
      fill: #615b59 !important;
      font-size: 48px;
    }
  }
  .cost {
    position: absolute;
    width: 40px;
    height: 25px;
    top: 4px;
    width: fit-content;
    max-width: calc(100% - 8px);
    padding: 0;
    background-color: #615b59;
    p {
      color: #fff !important;
      font-weight: 700 !important;
      font-size: 12px !important;
      overflow: hidden;
      height: 100%;
      padding: 4px 12px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .premium-img {
    filter: blur(4px);
    cursor: pointer;
  }
  .CTR-content {
    width: 100%;
    margin-top: 5px;
    p {
      font-weight: 700;
      font-size: 14px;
      span {
        font-weight: 500;
      }
    }
  }
  .min-interaction {
    min-height: 45px;
    .ir-title {
      font-weight: 700;
    }
    p {
      font-size: 14px;
      font-weight: normal;
      text-align: center;
    }
  }
`;

export const scrollTemplateSkeleton = (type: CampaignTypeEnum) => {
  if (type === CampaignTypeEnum.PHYSICAL)
    return (
      <TemplateITemStyled type={TemplateITemEnum.LETTER}>
        <Skeleton width={190} height={245} />
        <Skeleton width={190} height={10} />
        <Skeleton
          width={190}
          height={10}
          style={{
            transform: 'translateY(-8px)',
          }}
        />
      </TemplateITemStyled>
    );
  else if (type === CampaignTypeEnum.EMAIL)
    return (
      <TemplateITemStyled type={TemplateITemEnum.LETTER}>
        <Skeleton width={190} height={245} />
      </TemplateITemStyled>
    );
};
