import styled from '@emotion/styled';
import { Button, Input } from 'antd';
import AntButton from 'antd/es/button';
import AntSlider from 'antd/es/slider';
import React, { forwardRef, memo, useCallback, useImperativeHandle, useRef, useState } from 'react';
import Cropper from 'react-easy-crop';
import type { Area, Point } from 'react-easy-crop/types';

import {
  ASPECT_MAX,
  ASPECT_MIN,
  ASPECT_STEP,
  PREFIX,
  ROTATION_INITIAL,
  ROTATION_MAX,
  ROTATION_MIN,
  ROTATION_STEP,
  ZOOM_INITIAL,
  ZOOM_STEP,
} from './constants';
import type { EasyCropProps, EasyCropRef } from './types';

const EasyCrop = forwardRef<EasyCropRef, EasyCropProps>((props, ref) => {
  const {
    cropperRef,
    zoomSlider,
    rotationSlider,
    aspectSlider,
    showReset,
    resetBtnText,

    modalImage,
    aspect: ASPECT_INITIAL,
    minZoom,
    maxZoom,
    cropShape,
    showGrid,

    aspectSelector,
    customAspect,
    cropperProps,
  } = props;

  const [zoom, setZoom] = useState(ZOOM_INITIAL);
  const [rotation, setRotation] = useState(ROTATION_INITIAL);
  const [aspect, setAspect] = useState(ASPECT_INITIAL);

  const [isCustomAspectOpened, setCustomAspectOpened] = useState(false);
  const [wAspect, setWAspect] = useState(0);
  const [hAspect, setHAspect] = useState(1);

  const isResetActive = zoom !== ZOOM_INITIAL || rotation !== ROTATION_INITIAL || aspect !== ASPECT_INITIAL;

  const onReset = () => {
    setZoom(ZOOM_INITIAL);
    setRotation(ROTATION_INITIAL);
    setAspect(ASPECT_INITIAL);
  };

  const [crop, onCropChange] = useState<Point>({ x: 0, y: 0 });
  const cropPixelsRef = useRef<Area>({ width: 0, height: 0, x: 0, y: 0 });

  const onCropComplete = useCallback((_: Area, croppedAreaPixels: Area) => {
    cropPixelsRef.current = croppedAreaPixels;
  }, []);

  const onOpenCustomAspect = () => {
    setCustomAspectOpened(true);
  };

  useImperativeHandle(ref, () => ({
    rotation,
    cropPixelsRef,
    onReset,
  }));

  return (
    <CropperStyled>
      {(!!aspectSelector.length || customAspect) && (
        <div className="aspect-selector">
          {aspectSelector.map((ap) => (
            <Button
              key={ap.value}
              className={`aspect-btn ${aspect === ap.value ? 'active' : ''}`}
              onClick={() => {
                setAspect(ap.value);
              }}
            >
              {ap.label}
            </Button>
          ))}
          {customAspect && (
            <div>
              <Button
                className={`aspect-btn ${aspect === wAspect / hAspect ? 'active' : ''}`}
                onClick={onOpenCustomAspect}
              >
                Aspect
              </Button>
              {isCustomAspectOpened && (
                <div className="custom-aspect-wrapper">
                  <div className="custom-aspect">
                    <Input onChange={(e) => setWAspect(parseInt(e.target.value))} value={wAspect} />
                    <p>:</p>
                    <Input onChange={(e) => setHAspect(parseInt(e.target.value))} value={hAspect} />
                  </div>
                  <div className="custom-aspect-btn">
                    <Button className="aspect-btn" onClick={() => setCustomAspectOpened(false)}>
                      Cancel
                    </Button>
                    <Button
                      className="aspect-btn confirm"
                      onClick={() => {
                        setAspect(wAspect / hAspect);
                        setCustomAspectOpened(false);
                      }}
                    >
                      Confirm
                    </Button>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      )}

      <Cropper
        {...cropperProps}
        ref={cropperRef}
        image={modalImage}
        crop={crop}
        //
        zoom={zoom}
        rotation={rotation}
        aspect={aspect}
        minZoom={minZoom}
        maxZoom={maxZoom}
        zoomWithScroll={zoomSlider}
        //
        cropShape={cropShape}
        showGrid={showGrid}
        onCropChange={onCropChange}
        onZoomChange={setZoom}
        onRotationChange={setRotation}
        onCropComplete={onCropComplete}
        classes={{
          containerClassName: `${PREFIX}-container`,
          mediaClassName: `${PREFIX}-media`,
          cropAreaClassName: `${PREFIX}-area`,
        }}
      />

      {zoomSlider && (
        <section className={`${PREFIX}-control ${PREFIX}-control-zoom image-cropper-section`}>
          <button
            className={'image-cropper-btn'}
            onClick={() => setZoom(zoom - ZOOM_STEP)}
            disabled={zoom - ZOOM_STEP < minZoom}
          >
            －
          </button>
          <AntSlider
            className={'image-cropper-slider'}
            min={minZoom}
            max={maxZoom}
            step={ZOOM_STEP}
            value={zoom}
            onChange={setZoom}
          />
          <button
            className={'image-cropper-btn'}
            onClick={() => setZoom(zoom + ZOOM_STEP)}
            disabled={zoom + ZOOM_STEP > maxZoom}
          >
            ＋
          </button>
        </section>
      )}

      {rotationSlider && (
        <section className={`${PREFIX}-control ${PREFIX}-control-rotation image-cropper-section`}>
          <button
            className={'image-cropper-btn rotation-btn'}
            onClick={() => setRotation(rotation - ROTATION_STEP)}
            disabled={rotation === ROTATION_MIN}
          >
            ↺
          </button>
          <AntSlider
            className={'image-cropper-slider'}
            min={ROTATION_MIN}
            max={ROTATION_MAX}
            step={ROTATION_STEP}
            value={rotation}
            onChange={setRotation}
          />
          <button
            className={'image-cropper-btn rotation-btn'}
            onClick={() => setRotation(rotation + ROTATION_STEP)}
            disabled={rotation === ROTATION_MAX}
          >
            ↻
          </button>
        </section>
      )}

      {aspectSlider && (
        <section className={`${PREFIX}-control ${PREFIX}-control-aspect image-cropper-section`}>
          <button
            className={'image-cropper-btn'}
            onClick={() => setAspect(aspect - ASPECT_STEP)}
            disabled={aspect - ASPECT_STEP < ASPECT_MIN}
          >
            ↕️
          </button>
          <AntSlider
            className={'image-cropper-slider'}
            min={ASPECT_MIN}
            max={ASPECT_MAX}
            step={ASPECT_STEP}
            value={aspect}
            onChange={setAspect}
          />
          <button
            className={'image-cropper-btn'}
            onClick={() => setAspect(aspect + ASPECT_STEP)}
            disabled={aspect + ASPECT_STEP > ASPECT_MAX}
          >
            ↔️
          </button>
        </section>
      )}

      {showReset && (zoomSlider || rotationSlider || aspectSlider) && (
        <AntButton
          className="reset-btn"
          style={isResetActive ? {} : { opacity: 0.3, pointerEvents: 'none' }}
          onClick={onReset}
        >
          {resetBtnText}
        </AntButton>
      )}
    </CropperStyled>
  );
});

export default memo(EasyCrop);

const CropperStyled = styled.div`
  .image-crop-container {
    position: relative;
    width: 100%;
    height: 472px;
    max-height: 40vh;

    & ~ section:first-of-type {
      margin-top: 16px;
    }

    & ~ section:last-of-type {
      margin-bottom: -8px;
    }
  }

  .aspect-selector {
    display: flex;
    gap: 8px;
    margin-bottom: 16px;

    .aspect-btn {
      color: #150056;
      border-color: #d9d9d9;

      &.active {
        color: white;
        background: #04caae;
        border-color: #04caae;
      }
    }

    input {
      width: 80px;
      text-align: center;
    }

    .custom-aspect-wrapper {
      margin-top: 8px;
      border: 1px solid #d9d9d9;
      padding: 8px;
      border-radius: 4px;

      .custom-aspect {
        display: flex;
        align-items: center;
        gap: 4px;
        margin-bottom: 8px;
      }

      .custom-aspect-btn {
        display: flex;
        align-items: center;
        gap: 8px;

        .confirm {
          color: white;
          background: #04caae;
          border-color: #04caae;
        }
      }
    }
  }

  .image-crop-area {
    border: 3px solid #3399ff;

    &:before,
    &:after {
      border: 1px dashed #eee;
    }

    &:before {
      border-top: 0;
      border-bottom: 0;
    }

    &:after {
      border-left: 0;
      border-right: 0;
    }
  }

  .image-cropper-section {
    display: flex;
    align-items: center;
    width: 60%;
    margin-inline: auto;
  }

  .image-cropper-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
    background: transparent;
    border: 0;
    font-family: inherit;
    font-size: 18px;
    cursor: pointer;

    &:disabled {
      opacity: 20%;
      cursor: default;
    }

    &.rotation-btn {
      font-size: 16px;
    }
  }

  .reset-btn {
    position: absolute;
    bottom: 20px;
  }

  .image-cropper-slider {
    flex: 1;
  }
`;
