import { css } from '@emotion/react';
import { color as themeColor } from '@/styles/theme';
import { DigitalAdTypes } from '@goldfishcode/first-team-real-estate-sdk/libs/api/postalytics/models';
import { Image, Tooltip } from 'antd';
import React from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { RiErrorWarningLine } from 'react-icons/ri';
import ReactPlayer from 'react-player';
import { DigitalUploadType } from '@/utils/enum';
import UploadDigitalMediaViewContainer from './UploadDigitalMediaStyled';
import { FileType, SelectedFileNameDigitalMediaType, UploadMediaType } from './UploadDigitalMediaContainer';
import AppButtonV2 from '../AppButtonV2';
import AppLabel from '../AppLabel';

const videoFormats = ['.mp4', '.mov', '.wmv', '.avi'];
const imageFormats = ['.bmp', '.jpeg', '.jpg', '.png'];

type UploadDigitalMediaViewProps<T extends true | undefined> = FileType<T> & {
  mode?: DigitalAdTypes;
  buttonColor?: 'pink' | 'black';
  disabled?: boolean;
  title?: string;
  selectFileButtonLabel?: string;
  uploadType?: UploadMediaType;
  alignPreviewImage?: 'center' | 'left';
  className?: string;
  requiredMark?: boolean;
  isUseDisclaimer?: boolean;
  inputFileRef: React.RefObject<HTMLInputElement>;
  allowedExtensions: string[];
  digitalUploadLoading: boolean;
  selectedFileName: SelectedFileNameDigitalMediaType[] | null;
  labelColor?: keyof typeof themeColor;
  onDeleteFile?: (flag: boolean) => void;
  handleChangeMediaFile: (event: any) => Promise<void>;
  setSelectedFileName: React.Dispatch<React.SetStateAction<SelectedFileNameDigitalMediaType[] | null>>;
  onBtnClick: () => void;
  setDigitalUploadLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

const UploadDigitalMediaView = <T extends true | undefined = undefined>({
  title = 'Upload Media',
  selectFileButtonLabel = 'Select File',
  uploadType = UploadMediaType.DIGITAL_MEDIA,
  mode,
  disabled,
  buttonColor = 'black',
  alignPreviewImage = 'center',
  className,
  requiredMark,
  isUseDisclaimer = true,
  isUseMultiple = undefined,
  inputFileRef,
  allowedExtensions,
  digitalUploadLoading,
  selectedFileName,
  labelColor,
  onDeleteFile,
  handleChangeMediaFile,
  onBtnClick,
  setDigitalUploadLoading,
  setSelectedFileName,
}: UploadDigitalMediaViewProps<T>) => {
  return (
    <UploadDigitalMediaViewContainer buttonColor={buttonColor} className={className}>
      <div className="digital-btn_label_group">
        <div className="digital-btn_label">
          <AppLabel label={title} requiredMark={requiredMark} labelColor={labelColor}></AppLabel>
          {isUseDisclaimer && (
            <Tooltip
              overlayClassName="limit-size"
              overlayStyle={{ minWidth: '550px' }}
              title={
                <ul>
                  {uploadType === UploadMediaType.NORMAL_MEDIA && mode === DigitalAdTypes.DIGITAL_AD_IMAGE ? (
                    <>
                      <li>
                        <strong>File Size Limit:</strong> Make sure your image file is no larger than 10 megabytes (MB).
                      </li>
                    </>
                  ) : uploadType === UploadMediaType.HANDWRITTEN_IMAGE_MEDIA &&
                    mode === DigitalAdTypes.DIGITAL_AD_IMAGE ? (
                    <>
                      <li>
                        <strong>File Size Limit:</strong> Make sure your image file is no larger than 1 megabyte (MB).
                      </li>
                    </>
                  ) : (
                    <>
                      <li>
                        <strong>Video Size Limit:</strong> Make sure your video file is no larger than 50 megabytes
                        (MB).
                      </li>
                      <li>
                        <strong>Image Size Allowed:</strong>
                        <ul className="sub-item">
                          <li>
                            <strong>Mobile campaigns:</strong> 320x50 and 300x250. Additional accepted sizes include
                            320x480 and 728x90.
                          </li>
                          <li>
                            <strong>Desktop campaigns:</strong> 728x90 and 300x250. Additional accepted sizes include
                            160x600 and 300x600.
                          </li>
                        </ul>
                      </li>
                      <li>
                        <strong>Duration Limit:</strong> Videos should not exceed a duration of 30 seconds.
                      </li>
                      <li>
                        <strong>Accepted Video Formats:</strong> Only{' '}
                        {videoFormats.map((v) => v.toUpperCase()).join(' | ')} formats are accepted.
                      </li>
                    </>
                  )}
                  <li>
                    <strong>Accepted Image Formats:</strong> Only {imageFormats.map((v) => v.toUpperCase()).join(' | ')}{' '}
                    formats are accepted.
                  </li>
                </ul>
              }
            >
              <RiErrorWarningLine size={20} color="#04CAAE" />
            </Tooltip>
          )}
        </div>
        <div className="upload-file">
          <div>
            <input
              type="file"
              name="file"
              onChange={handleChangeMediaFile}
              style={{ display: 'none' }}
              ref={inputFileRef}
              accept={allowedExtensions.join(', ')}
              title="Upload File"
            />
            <AppButtonV2
              width="168px"
              loading={digitalUploadLoading}
              onClick={onBtnClick}
              disabled={disabled}
              letter="middle"
            >
              {selectFileButtonLabel}{' '}
            </AppButtonV2>
          </div>
          <div className="file-name-preview-container">
            {selectedFileName &&
              selectedFileName.map((v) => {
                return (
                  <div className="selected-file" key={v?.selectedFileData?.id}>
                    <div style={{ textAlign: 'left' }}>
                      {!isUseMultiple && <h3>Selected file:</h3>}
                      <p title={v?.selectedFileName}>{v?.selectedFileName}</p>
                    </div>
                    <AiOutlineClose
                      onClick={() => {
                        setSelectedFileName(
                          (prev) => prev?.filter((fileName) => fileName.selectedFileName !== v.selectedFileName) || [],
                        );
                        setDigitalUploadLoading(false);
                        onDeleteFile && onDeleteFile(true);
                      }}
                      size={16}
                      style={{ marginLeft: 5, cursor: 'pointer' }}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <div className="media-container">
        {selectedFileName &&
          selectedFileName.map((item) => {
            return (
              item?.selectedFileData &&
              item?.file_type === DigitalUploadType.VIDEO && (
                <div
                  css={css`
                    display: flex;
                    justify-content: center;
                  `}
                >
                  <ReactPlayer
                    id="ff-video"
                    url={item?.selectedFileData?.url}
                    controls
                    width="280px"
                    height="150px"
                    playing={false}
                    config={{
                      file: {
                        attributes: {
                          crossOrigin: 'anonymous',
                        },
                      },
                    }}
                  />
                </div>
              )
            );
          })}
        {selectedFileName &&
          selectedFileName?.map((item) => {
            return (
              item?.selectedFileData &&
              item?.file_type === DigitalUploadType.IMAGE && (
                <div
                  css={css`
                    width: auto;
                    height: 150px;
                    text-align: ${alignPreviewImage};
                    .ant-image {
                      width: auto;
                      height: 100%;
                      .ant-image-img {
                        max-width: 280px;
                        max-height: 150px;
                      }
                    }
                  `}
                >
                  <Image src={item?.selectedFileData?.url} preview={false} />
                </div>
              )
            );
          })}
      </div>
    </UploadDigitalMediaViewContainer>
  );
};

export default React.memo(UploadDigitalMediaView) as <T extends true | undefined = undefined>(
  props: UploadDigitalMediaViewProps<T>,
) => JSX.Element;
