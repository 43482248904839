import { LoadingOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Spin, SpinProps } from 'antd';
import React, { CSSProperties, useMemo } from 'react';
import { forceLogout } from '@/utils/auth';
import { LoadingSkeleton } from '../skeleton';

export interface ILoadingPageProps {
  isLoading: boolean;
  cssName?: string;
  error?: any;
}

export const LoadingPage: React.FC<ILoadingPageProps> = (props: ILoadingPageProps) => {
  const { cssName, isLoading, error } = props;

  const loader = useMemo(
    () => (
      <LoadingPageWrapper className={`${cssName}`}>
        <div className="spiner-first-team">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </LoadingPageWrapper>
    ),
    [],
  );

  if (!!error || (error && Object.keys(error).length > 0)) {
    forceLogout();
    return loader;
  }

  if (!isLoading) {
    return null;
  }

  return loader;
};
export enum NumPosition {
  CENTER = 'CENTER',
  TOP = 'TOP',
}

interface LoadingContentProps {
  isLoading: boolean;
  className?: string;
  position?: NumPosition;
  type?: 'LOADING' | 'SKELETON';
  color?: string;
}
export const InnerLoading = ({ iconStyle, ...props }: SpinProps & { iconStyle?: CSSProperties }) => {
  const antIcon = <LoadingOutlined style={{ fontSize: 29, ...iconStyle }} spin />;
  return <Spin {...props} indicator={antIcon} />;
};

export const LoadingContent: React.FC<LoadingContentProps> = (props) => {
  const { isLoading, children, className, position = NumPosition.CENTER, type = 'LOADING', color = '#150056' } = props;

  if (type === 'SKELETON') {
    return isLoading ? <LoadingSkeleton /> : <div>{children || <DivBlank />}</div>;
  }
  return (
    <div
      css={css`
        ${isLoading && 'position: relative; opacity: .6;'}
      `}
      className={className ?? ''}
    >
      {isLoading && (
        <div
          className="absolute w-full h-full flex justify-center items-center"
          css={css`
            position: absolute;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center !important;
            align-items: ${position === NumPosition.CENTER ? 'center' : 'start'} !important;
            z-index: 999;
            user-select: none;
            ${position === NumPosition.TOP && 'padding-top:15%;'}
            .ant-spin {
              color: ${color}!important;
              .anticon-loading {
                font-size: 40px !important;
              }
            }
          `}
        >
          <InnerLoading size="large" />
        </div>
      )}
      {children ? children : <DivBlank />}
    </div>
  );
};

export const BouncingDotsLoader = (props) => {
  const { isLoading } = props;
  if (!isLoading) {
    return null;
  }
  return (
    <div
      css={css`
        .bouncing-loader {
          display: flex;
          justify-content: center;
        }

        .bouncing-loader > div {
          width: 4px;
          height: 4px;
          margin: 1px 2px;
          border-radius: 50%;
          background-color: #a3a1a1;
          opacity: 1;
          animation: bouncing-loader 0.6s infinite alternate;
        }
        @keyframes bouncing-loader {
          to {
            opacity: 0.1;
            transform: translateY(-10px);
          }
        }

        .bouncing-loader > div:nth-of-type(2) {
          animation-delay: 0.2s;
        }

        .bouncing-loader > div:nth-of-type(3) {
          animation-delay: 0.4s;
        }
      `}
    >
      <div className="bouncing-loader">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export const DivBlank = styled.div`
  min-height: 100px;
  height: 100%;
  background-color: #e7edf3;
`;

const LoadingPageWrapper = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(239, 239, 239, 0.48);
  z-index: 9999;
  display: grid;
  justify-content: center;
  align-items: center;
  .spiner-first-team {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .spiner-first-team div {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 16px;
    background: #150056;
    animation: spiner-first-team 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  }
  .spiner-first-team div:nth-of-type(1) {
    left: 8px;
    animation-delay: -0.24s;
  }
  .spiner-first-team div:nth-of-type(2) {
    left: 32px;
    animation-delay: -0.12s;
  }
  .spiner-first-team div:nth-of-type(3) {
    left: 56px;
    animation-delay: 0;
  }
  @keyframes spiner-first-team {
    0% {
      top: 8px;
      height: 64px;
    }
    50%,
    100% {
      top: 24px;
      height: 32px;
    }
  }
`;
