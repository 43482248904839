import React from 'react';
import { Tooltip } from 'antd';
import { RiErrorWarningLine } from 'react-icons/ri';
import { TooltipPlacement } from 'antd/lib/tooltip';

const DisclaimerTooltip = ({ title, placement }: { title: string; placement?: TooltipPlacement }) => {
  if (!title) return null;
  return (
    <Tooltip title={title} placement={placement ?? 'top'}>
      <RiErrorWarningLine size={17} style={{ marginLeft: 4, transform: 'translateY(3px)' }} color="#04CAAE" />
    </Tooltip>
  );
};

export default DisclaimerTooltip;
