import styled from '@emotion/styled';

export const HandwrittenWrapperStyled = styled.div`
  padding: 0;
  padding-left: 32px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  height: max-content;
  gap: 16px;
  .hidden {
    display: none;
  }
  .image-upload-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .handwritten-input-message {
      margin-bottom: 15px;
    }
  }
  .handwritten-input-container {
    width: 100%;
    height: 100%;
    margin-bottom: 16px;
    border-radius: 4px;
    position: relative;
    .field {
      margin-bottom: 0;
    }
    &.handwritten-input-message,
    &.handwritten-input-signature {
      margin-bottom: 41px;
    }
    .handwritten-header-option {
      margin-bottom: 10px;
    }
    .template-name-field {
      min-height: 48px !important;
      input.ant-input {
        background-color: white;
      }
    }
    .handwritten-limit-char {
      margin-top: 5px;
      font-weight: 500;
      font-style: italic;
    }

    .handwritten-editor-header {
      display: flex;
      justify-content: space-between;
    }
    .handwritten-message-font {
      margin-top: 10px;
    }
    .ant-radio-button-wrapper {
      font-size: 14px;
      font-weight: 600;
      text-align: center;
      background-color: transparent;
      color: #ffff !important;
      border-color: #04caae !important;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
      @media only screen and (min-width: 2000px) {
        min-width: 184px;
      }
      &::hover,
      &::focus,
      &::active {
        border-color: #04caae;
        color: #ffff !important;
      }
      &::before {
        display: none;
      }
      &.ant-radio-button-wrapper-checked {
        background-color: #04caae;

        &::hover,
        &::focus,
        &::active {
          border-color: #04caae;
          color: #04caae !important;
        }
      }
    }
    .hidden {
      display: none;
    }
    .placeholder {
      opacity: 0.5;
    }
    .centerAlign {
      text-align: center;
    }
    .errorMsg {
      color: red;
    }
    .disabled-field {
      opacity: 0.8;
    }
    .cke_top {
      border-bottom: none;
      background: transparent;
    }
    .cke_bottom {
      border-top: none;
      background: transparent;
    }
    &.haft-size {
      max-width: 49%;
    }
    &.handwritten-input-editor-container {
      display: flex;
      gap: 10px;
      .handwritten-preview-container {
        width: 100%;
        max-width: 49%;
        .handwritten-card-option {
          margin: 10px 0;
          text-align: center;
        }
      }
      .handwritten-input-preview {
        width: 100%;
        aspect-ratio: 1380 / 1060;
        background: #fff;
        color: #000;
        img {
          height: 100%;
        }
        &.handwritten-card-back {
          overflow: auto;

          &::-webkit-scrollbar-track {
            background: #fff;
          }

          &::-webkit-scrollbar-thumb {
            background: #fff;
            border-radius: 4px;
          }

          &:hover::-webkit-scrollbar-thumb {
            background: #c1c1c1;
          }
        }
        .handwritten-input-header {
          height: 70px;
          img {
            max-height: 70px;
          }
        }
        .handwritten-header-text-preview {
          margin: 5px;
        }
      }
    }
  }
  .handwritten-upload-image-container {
    display: flex;
    align-items: center;

    .checkbox-customize {
      align-self: self-start;
      padding: 0;
      padding-top: 40px;
      & span {
        color: #04caae;
      }
    }
    .or-divide {
      align-self: self-start;
      padding: 0;
      padding-top: 44px;
      margin-left: 16px;
      margin-right: 50px;
    }
  }
`;
